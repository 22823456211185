import { EntityState } from "@ngrx/entity";
import { ActionReducerMap } from "@ngrx/store";
import {
  AllowedAutoGradeTypesDto,
  AutoGradeParticipantDto,
  GradeDto,
  GradeTypeDto,
  GradesSummary,
} from "@store/projects/model/dto/grade.dto";
import { LineDto } from "@store/projects/model/dto/line.dto";
import { ProjectDto } from "@store/projects/model/dto/projects.dto";
import { TellsyTeamDto } from "@store/projects/model/dto/team.dto";
import { TellsyActivityDto } from "@store/projects/model/dto/tellsy-activities.dto";
import { TellsyEventDto } from "@store/projects/model/dto/tellsy-event.dto";
import { TellsyParticipantDto } from "@store/projects/model/dto/tellsy-participant.dto";
import { gradesReducer } from "@store/projects/reducers/grades.reducer";
import { linesReducer } from "@store/projects/reducers/lines.reducer";
import { tellsyParticipantsReducer } from "@store/projects/reducers/participants.reducer";
import { projectsReducer } from "@store/projects/reducers/projects.reducer";
import { tellsyActivitiesReducer } from "@store/projects/reducers/tellsy-activities.reducer";
import { tellsyEventsReducer } from "@store/projects/reducers/tellsy-events.reducer";
import { tellsyTeamsReducer } from "@store/projects/reducers/tellsy-teams.reducer";

export const PROJECTS_STATE_ID = "projects";

export interface ProjectsState {
  projects: ProjectsProjectsState;
  lines: LinesState;
  grades: GradesState;
  tellsyEvents: TellsyEventsState;
  tellsyActivities: TellsyActivitiesState;
  tellsyParticipants: TellsyParticipantsState;
  tellsyTeams: TellsyTeamsState;
}

export const projectsReducers: ActionReducerMap<ProjectsState> = {
  projects: projectsReducer,
  lines: linesReducer,
  grades: gradesReducer,
  tellsyEvents: tellsyEventsReducer,
  tellsyActivities: tellsyActivitiesReducer,
  tellsyParticipants: tellsyParticipantsReducer,
  tellsyTeams: tellsyTeamsReducer,
};

export interface ProjectsProjectsState extends EntityState<ProjectDto> {
  isLoading: {
    loadingProjectsPending: boolean;
    addProjectPending: boolean;
    updateProjectPending: boolean;
    deleteProjectPending: boolean;
  };
}

export interface LinesState extends EntityState<LineDto> {}

export interface GradesState {
  gradeTypes: EntityState<GradeTypeDto>;
  allowedAutoGradeTypes: EntityState<AllowedAutoGradeTypesDto>;
  grades: EntityState<GradeDto>;
  autoGrades: EntityState<AutoGradeParticipantDto>;
  gradesSummary: EntityState<GradesSummary>;
  isLoading: {
    gradeTypes: boolean;
    grades: boolean;
    autoGrades: boolean;
    gradesSummary: boolean;
    report: boolean;
  };
}

export interface TellsyEventsState extends EntityState<TellsyEventDto> {
  pageableMetadata: { page: number; last: boolean; search: string } | null;
  loadingTellsyEventsPending: boolean;
}

export interface TellsyActivitiesState extends EntityState<TellsyActivityDto> {
  isLoading: boolean;
}

export interface TellsyParticipantsState
  extends EntityState<TellsyParticipantDto> {
  isLoading: boolean;
}

export interface TellsyTeamsState extends EntityState<TellsyTeamDto> {
  isLoading: boolean;
}
