import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { TellsyActivitiesActions } from "@store/projects/actions/";
import { TellsyActivityDto } from "@store/projects/model/dto/tellsy-activities.dto";
import { TellsyActivitiesState } from "@store/projects/reducers";

export const tellsyActivitiesAdapter: EntityAdapter<TellsyActivityDto> =
  createEntityAdapter<TellsyActivityDto>({
    selectId: (a) => a.activityId,
    sortComparer: (a, b) => a.order - b.order,
  });

export const initialState: TellsyActivitiesState =
  tellsyActivitiesAdapter.getInitialState({ isLoading: false });

export const tellsyActivitiesReducer = createReducer<TellsyActivitiesState>(
  initialState,

  on(
    TellsyActivitiesActions.loadTellsyActivities,
    (state): TellsyActivitiesState => ({ ...state, isLoading: true })
  ),

  on(
    TellsyActivitiesActions.loadTellsyActivitiesSuccess,
    (state, { dto }): TellsyActivitiesState =>
      tellsyActivitiesAdapter.setAll(dto, { ...state, isLoading: false })
  )
);

export const tellsyActivitiesSelectors = tellsyActivitiesAdapter.getSelectors(
  (state: TellsyActivitiesState) => state
);
