import { Injectable, Signal } from "@angular/core";
import { Store } from "@ngrx/store";
import { ProjectsActions } from "@store/projects/actions";
import {
  AddProjectDto,
  DeleteProjectDto,
  LoadProjectDto,
  ProjectDto,
  UpdateProjectDto,
} from "@store/projects/model/dto/projects.dto";
import { ProjectsCombinedSelectors } from "@store/projects/selectors/combined.selectors";
import { ProjectsSelectors } from "@store/projects/selectors/projects.selectors";

@Injectable({
  providedIn: "root",
})
export class ProjectsFacade {
  constructor(private store: Store) {}

  loadProjects(): void {
    this.store.dispatch(ProjectsActions.loadProjects());
  }

  loadProject(dto: LoadProjectDto): void {
    this.store.dispatch(ProjectsActions.loadProject({ dto }));
  }

  addProject(dto: AddProjectDto): void {
    this.store.dispatch(ProjectsActions.addProject({ dto }));
  }

  updateProject(dto: UpdateProjectDto): void {
    this.store.dispatch(ProjectsActions.updateProject({ dto }));
  }

  deleteProject(dto: DeleteProjectDto): void {
    this.store.dispatch(ProjectsActions.deleteProject({ dto }));
  }

  // selectors
  selectCurrentProject(): Signal<ProjectDto | undefined> {
    return this.store.selectSignal(
      ProjectsCombinedSelectors.selectCurrentProject
    );
  }

  selectAllProjectsViewModel(): Signal<ProjectDto[]> {
    return this.store.selectSignal(
      ProjectsCombinedSelectors.selectAllProjectsViewModel
    );
  }

  selectAll(): Signal<ProjectDto[]> {
    return this.store.selectSignal(ProjectsSelectors.selectAllProjects);
  }

  selecProject(id: ProjectDto["id"]): Signal<ProjectDto | undefined> {
    return this.store.selectSignal(ProjectsSelectors.selectProject(id));
  }

  selectIsLoadingProjectsPending(): Signal<boolean> {
    return this.store.selectSignal(
      ProjectsSelectors.selectIsLoadingProjectsPending
    );
  }

  selectIsAddProjectPending(): Signal<boolean> {
    return this.store.selectSignal(ProjectsSelectors.selectIsAddProjectPending);
  }

  selectIsUpdateProjectPending(): Signal<boolean> {
    return this.store.selectSignal(
      ProjectsSelectors.selectIsUpdateProjectPending
    );
  }

  selectIsDeleteProjectPending(): Signal<boolean> {
    return this.store.selectSignal(
      ProjectsSelectors.selectIsDeleteProjectPending
    );
  }
}
