import { createActionGroup, emptyProps, props } from "@ngrx/store";
import {
  AddProjectDto,
  AddProjectSuccessDto,
  DeleteProjectDto,
  DeleteProjectSuccessDto,
  LoadProjectDto,
  LoadProjectsSuccessDto,
  LoadProjectSuccessDto,
  UpdateProjectDto,
  UpdateProjectSuccessDto,
} from "@store/projects/model/dto/projects.dto";
import { ExcludedParticipantsDto } from "@store/projects/model/dto/line.dto";

export const ProjectsActions = createActionGroup({
  source: "Projects",
  events: {
    "Load Projects": emptyProps(),
    "Load Projects Success": props<{ dto: LoadProjectsSuccessDto }>(),

    "Load Project": props<{ dto: LoadProjectDto }>(),
    "Load Project Success": props<{ dto: LoadProjectSuccessDto }>(),

    "Add Project": props<{ dto: AddProjectDto }>(),
    "Add Project Success": props<{ dto: AddProjectSuccessDto }>(),

    "Update Project": props<{ dto: UpdateProjectDto }>(),
    "Update Project Success": props<{ dto: UpdateProjectSuccessDto }>(),

    "Delete Project": props<{ dto: DeleteProjectDto }>(),
    "Delete Project Success": props<{ dto: DeleteProjectSuccessDto }>(),
    "Update Excluded Tellsy Participants Success": props<ExcludedParticipantsDto>(),
  },
});
