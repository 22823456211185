import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ProjectsActions } from "@store/projects/actions";
import { ProjectsService } from "@store/projects/services";
import { map, switchMap, tap } from "rxjs";

@Injectable()
export class ProjectsEffects {
  loadProjects = createEffect(() => {
    return this.actions.pipe(
      ofType(ProjectsActions.loadProjects),
      switchMap(() => this.service.loadProjects()),
      map((dto) => ProjectsActions.loadProjectsSuccess({ dto }))
    );
  });

  loadProject = createEffect(() => {
    return this.actions.pipe(
      ofType(ProjectsActions.loadProject),
      switchMap(({ dto }) => this.service.loadProject(dto.id)),
      map((dto) => ProjectsActions.loadProjectSuccess({ dto }))
    );
  });

  addProject = createEffect(() => {
    return this.actions.pipe(
      ofType(ProjectsActions.addProject),
      switchMap(({ dto }) => this.service.addProject(dto)),
      map((dto) => ProjectsActions.addProjectSuccess({ dto }))
    );
  });

  addProjectSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(ProjectsActions.addProjectSuccess),
        tap(() =>
          this.snackBar.open("Проект создан", "ОК", {
            duration: 3000,
          })
        )
      );
    },
    { dispatch: false }
  );

  updateProject = createEffect(() => {
    return this.actions.pipe(
      ofType(ProjectsActions.updateProject),
      switchMap(({ dto }) => this.service.updateProject(dto)),
      map((dto) => ProjectsActions.updateProjectSuccess({ dto }))
    );
  });

  updateProjectSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(ProjectsActions.updateProjectSuccess),
        tap(() =>
          this.snackBar.open("Проект сохранён", "ОК", {
            duration: 3000,
          })
        )
      );
    },
    { dispatch: false }
  );

  deleteProject = createEffect(() => {
    return this.actions.pipe(
      ofType(ProjectsActions.deleteProject),
      switchMap(({ dto }) => this.service.deleteProject(dto)),
      map((dto) => ProjectsActions.deleteProjectSuccess({ dto }))
    );
  });

  deleteProjectSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(ProjectsActions.deleteProjectSuccess),
        tap(() =>
          this.snackBar.open("Проект удалён", "ОК", {
            duration: 3000,
          })
        )
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions: Actions,
    private service: ProjectsService,
    private snackBar: MatSnackBar
  ) {}
}
