import { Injectable, Signal } from "@angular/core";
import { Store } from "@ngrx/store";
import { TellsyActivitiesActions } from "@store/projects/actions";
import {
  LoadTellsyActivitiesDto,
  TellsyActivityDto,
} from "@store/projects/model/dto/tellsy-activities.dto";
import { TellsyActivitiesSelectors } from "@store/projects/selectors/tellsy-activities.selectors";

@Injectable({
  providedIn: "root",
})
export class TellsyActivitiesFacade {
  constructor(private store: Store) {}
  loadTellsyActivities(dto: LoadTellsyActivitiesDto): void {
    this.store.dispatch(TellsyActivitiesActions.loadTellsyActivities({ dto }));
  }

  // selectors

  selectAllTellsyActivities(): Signal<TellsyActivityDto[]> {
    return this.store.selectSignal(
      TellsyActivitiesSelectors.selectAllTellsyActivities
    );
  }
}
