import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { GradesActions, ProjectsActions } from "@store/projects/actions";
import { LineDto } from "@store/projects/model/dto/line.dto";
import { LinesState } from "@store/projects/reducers";

export const linesAdapter: EntityAdapter<LineDto> =
  createEntityAdapter<LineDto>();

export const initialState: LinesState = linesAdapter.getInitialState({});

export const linesReducer = createReducer<LinesState>(
  initialState,
  on(ProjectsActions.loadProjectsSuccess, (state, { dto }): LinesState => {
    const lines = dto.reduce<LineDto[]>((result, project) => {
      return [...result, ...project.lines];
    }, []);

    return linesAdapter.setAll(lines, state);
  }),

  on(
    ProjectsActions.loadProjectSuccess,
    (state, { dto }): LinesState => linesAdapter.setMany(dto.lines, state)
  ),

  on(
    ProjectsActions.addProjectSuccess,
    (state, { dto }): LinesState => linesAdapter.setMany(dto.lines, state)
  ),

  on(ProjectsActions.updateProjectSuccess, (state, { dto }): LinesState => {
    // update lines that were updated
    let lines = linesAdapter.setMany(dto.lines, state);

    // remove lines that were deleted
    lines = linesAdapter.removeMany((line) => {
      if (line.projectId !== dto.id) {
        return false;
      }
      const linesIds = dto.lines.map(({ id }) => id);
      return !linesIds.includes(line.id);
    }, lines);

    return lines;
  }),

  on(
    ProjectsActions.deleteProjectSuccess,
    (state, { dto }): LinesState =>
      linesAdapter.removeMany((line) => line.projectId === dto, state)
  ),

  on(
    ProjectsActions.deleteProjectSuccess,
    (state, { dto }): LinesState =>
      linesAdapter.removeMany((line) => line.projectId === dto, state)
  ),

  on(
    GradesActions.editAutoGradeTypesSuccess,
    (state, { dayId, lineId, dto }): LinesState =>
      linesAdapter.mapOne(
        {
          id: lineId,
          map: (line) => ({
            ...line,
            days: line.days.map((day) =>
              day.id === dayId ? { ...day, autoGradeTypes: dto } : day
            ),
          }),
        },
        state
      )
  ),

  on(ProjectsActions.updateExcludedTellsyParticipantsSuccess, (state, { lineId, tellsyExcludedParticipantIds }) =>
    linesAdapter.updateOne(
      { id: lineId, changes: { tellsyExcludedParticipantIds } },
      state
    )
  )
);

export const linesSelectors = linesAdapter.getSelectors(
  (state: LinesState) => state
);
