import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { GradesActions } from "@store/projects/actions";
import {
  AllowedAutoGradeTypesDto,
  AutoGradeParticipantDto,
  GradeDto,
  GradeTypeDto,
  GradesSummary,
} from "@store/projects/model/dto/grade.dto";
import { GradesState } from "@store/projects/reducers";

export const gradesTypesAdapter: EntityAdapter<GradeTypeDto> =
  createEntityAdapter<GradeTypeDto>({});

export const allowedAutoGradesTypesAdapter: EntityAdapter<AllowedAutoGradeTypesDto> =
  createEntityAdapter<AllowedAutoGradeTypesDto>({
    selectId: (a) => a.activityMode,
  });

export const gradesAdapter: EntityAdapter<GradeDto> =
  createEntityAdapter<GradeDto>({});

export const autoGradesAdapter: EntityAdapter<AutoGradeParticipantDto> =
  createEntityAdapter<AutoGradeParticipantDto>({
    selectId: (a) => a.tellsyUserName,
  });

export const gradesSummaryAdapter: EntityAdapter<GradesSummary> =
  createEntityAdapter<GradesSummary>({ selectId: (a) => a.tellsyUserName });

export const initialState: GradesState = {
  gradeTypes: gradesTypesAdapter.getInitialState({}),
  allowedAutoGradeTypes: allowedAutoGradesTypesAdapter.getInitialState({}),
  grades: gradesAdapter.getInitialState({}),
  autoGrades: autoGradesAdapter.getInitialState({}),
  gradesSummary: gradesSummaryAdapter.getInitialState({}),
  isLoading: {
    grades: false,
    autoGrades: false,
    gradesSummary: false,
    gradeTypes: false,
    report: false,
  },
};

export const gradesReducer = createReducer<GradesState>(
  initialState,

  // grades types
  on(
    GradesActions.loadGradeTypesByLineIdSuccess,
    (state, { dto }): GradesState => ({
      ...state,
      gradeTypes: gradesTypesAdapter.setAll(dto, state.gradeTypes),
    })
  ),

  on(
    GradesActions.createGradeTypeSuccess,
    (state, { dto }): GradesState => ({
      ...state,
      gradeTypes: gradesTypesAdapter.addOne(dto, state.gradeTypes),
    })
  ),

  on(
    GradesActions.createGradeTypesSuccess,
    (state, { dto }): GradesState => ({
      ...state,
      gradeTypes: gradesTypesAdapter.setMany(dto, state.gradeTypes),
    })
  ),

  on(GradesActions.editGradeTypesSuccess, (state, { dto }): GradesState => {
    // update grade types that were updated
    let gradeTypes = gradesTypesAdapter.setMany(
      dto.gradeTypeDtos,
      state.gradeTypes
    );

    // remove grade types that were deleted
    gradeTypes = gradesTypesAdapter.removeMany((gradeType) => {
      if (gradeType.lineId !== dto.lineId) {
        return false;
      }
      const gradeTypeIds = dto.gradeTypeDtos.map(({ id }) => id);
      return !gradeTypeIds.includes(gradeType.id);
    }, gradeTypes);

    return {
      ...state,
      gradeTypes,
    };
  }),

  on(
    GradesActions.deleteGradeTypeSuccess,
    (state, { dto }): GradesState => ({
      ...state,
      gradeTypes: gradesTypesAdapter.removeOne(dto, state.gradeTypes),
    })
  ),

  // grades
  on(
    GradesActions.loadGradesSummaryForDay,
    (state): GradesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        gradesSummary: true,
      },
    })
  ),
  on(
    GradesActions.loadGradesSummaryForDaySuccess,
    (state, { dto }): GradesState => ({
      ...state,
      gradesSummary: gradesSummaryAdapter.setAll(dto, state.gradesSummary),
      isLoading: {
        ...state.isLoading,
        gradesSummary: false,
      },
    })
  ),

  on(
    GradesActions.createGradeSuccess,
    (state, { dto }): GradesState => ({
      ...state,
      grades: gradesAdapter.setOne(dto, state.grades),
    })
  ),

  on(
    GradesActions.editGradeSuccess,
    (state, { dto }): GradesState => ({
      ...state,
      grades: gradesAdapter.updateOne(
        { id: dto.id, changes: dto },
        state.grades
      ),
    })
  ),

  on(
    GradesActions.editMultipleGradesSuccess,
    (state, { dto }): GradesState => ({
      ...state,
      grades: gradesAdapter.setMany(dto.grades, state.grades),
      gradesSummary: gradesSummaryAdapter.setMany(
        dto.summary,
        state.gradesSummary
      ),
    })
  ),

  on(
    GradesActions.createGradeForMultipleParticipantSuccess,
    (state, { dto }): GradesState => ({
      ...state,
      grades: gradesAdapter.setMany(dto.grades, state.grades),
      gradesSummary: gradesSummaryAdapter.setMany(
        dto.summary,
        state.gradesSummary
      ),
    })
  ),

  on(
    GradesActions.deleteGradeSuccess,
    (state, { dto }): GradesState => ({
      ...state,
      grades: gradesAdapter.removeOne(dto, state.grades),
    })
  ),

  on(
    GradesActions.loadAllGradesByActivityAndUserNameSuccess,
    (state, { dto }): GradesState => ({
      ...state,
      grades: gradesAdapter.setMany(dto, state.grades),
    })
  ),

  on(
    GradesActions.loadGradesByActivityAndParticipantId,
    (state): GradesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        grades: true,
      },
    })
  ),

  on(
    GradesActions.loadGradesByActivityAndParticipantIdSuccess,
    (state, { dto }): GradesState => ({
      ...state,
      grades: gradesAdapter.setMany(dto, state.grades),
      isLoading: {
        ...state.isLoading,
        grades: false,
      },
    })
  ),

  on(
    GradesActions.loadAutoGrades,
    (state): GradesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        autoGrades: true,
      },
    })
  ),

  on(
    GradesActions.loadAutoGradesSuccess,
    (state, { dto }): GradesState => ({
      ...state,
      autoGrades: autoGradesAdapter.setAll(dto, state.autoGrades),
      isLoading: {
        ...state.isLoading,
        autoGrades: false,
      },
    })
  ),

  on(
    GradesActions.editAutoGradeTypes,
    (state): GradesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        autoGrades: true,
      },
    })
  ),

  on(
    GradesActions.editAutoGradeTypesSuccess,
    (state): GradesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        autoGrades: false,
      },
    })
  ),

  on(
    GradesActions.loadAllowedAutoGradeTypesSuccess,
    (state, { dto }): GradesState => ({
      ...state,
      allowedAutoGradeTypes: allowedAutoGradesTypesAdapter.setAll(
        dto,
        state.allowedAutoGradeTypes
      ),
    })
  ),

  on(
    GradesActions.loadProjectExcelReport,
    (state): GradesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        report: true,
      },
    })
  ),

  on(
    GradesActions.loadProjectExcelReportSuccess,
    GradesActions.loadProjectExcelReportFail,
    (state): GradesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        report: false,
      },
    })
  )
);

export const gradesTypesSelectors = gradesTypesAdapter.getSelectors(
  (state: GradesState) => state.gradeTypes
);

export const gradesSelectors = gradesAdapter.getSelectors(
  (state: GradesState) => state.grades
);

export const autoGradesSelectors = autoGradesAdapter.getSelectors(
  (state: GradesState) => state.autoGrades
);

export const allowedAutoGradesTypesSelectors =
  allowedAutoGradesTypesAdapter.getSelectors(
    (state: GradesState) => state.allowedAutoGradeTypes
  );

export const gradesSummarySelectors = gradesSummaryAdapter.getSelectors(
  (state: GradesState) => state.gradesSummary
);
