import { OmitStrict } from "@shared/utils/typescript.utils";
import { AutoGradeTypeDto, DayDto } from "@store/projects/model/dto/day.dto";
import { ExcludedParticipantsDto, LineDto } from "@store/projects/model/dto/line.dto";
import { ActivityTypeDto, TellsyActivityDto, } from "@store/projects/model/dto/tellsy-activities.dto";
import { TellsyParticipantDto } from "@store/projects/model/dto/tellsy-participant.dto";

export interface GradeTypeDto {
  id: number;
  lineId: LineDto["id"];
  title: string;
  description: string;
}

export interface GradeDto {
  id: number;
  gradeTypeId: GradeTypeDto["id"];
  dayId: DayDto["id"];
  tellsyActivityId: TellsyActivityDto["activityId"];
  tellsyParticipantId: TellsyParticipantDto["participantId"];
  tellsyUserName: TellsyParticipantDto["username"];
  score: number;
}

export interface GetGradeTypesByLineIdDto {
  lineId: LineDto["id"];
}

export type GetGradeTypesByLineIdSuccessDto = GradeTypeDto[];

export interface CreateGradeTypeDto extends OmitStrict<GradeTypeDto, "id"> {
}

export interface CreateGradeTypeSuccessDto extends GradeTypeDto {
}

export interface CreateGradeTypesDto {
  lineId: LineDto["id"];
  gradeTypeDtos: OmitStrict<GradeTypeDto, "id">[];
}

export type CreateGradeTypesSuccessDto = GradeTypeDto[];

export interface EditGradeTypeDto extends OmitStrict<GradeTypeDto, "id"> {
  id: number | null;
}

export interface EditGradeTypeSuccessDto extends GradeTypeDto {
}

export interface EditGradeTypesDto {
  lineId: LineDto["id"];
  gradeTypeDtos: EditGradeTypeDto[];
}

export interface EditGradeTypesSuccessDto {
  lineId: LineDto["id"];
  gradeTypeDtos: GradeTypeDto[];
}

export type DeleteGradeTypeDto = GradeTypeDto["id"];

export type DeleteGradeTypeSuccessDto = GradeTypeDto["id"];

export type GetGradesSummaryPerParticipantDto = DayDto["id"];

export interface ActivityScore {
  tellsyActivityId: string;
  activityScore: number;
}

export interface GradesSummary {
  dayId: DayDto["id"];
  tellsyParticipantId: string;
  tellsyUserName: string;
  dailyScore: number;
  activityScores: ActivityScore[];
}

export type GetGradesSummaryPerParticipantSuccessDto = GradesSummary[];

export interface CreateGradeDto extends OmitStrict<GradeDto, "id"> {
}

export interface CreateGradeSuccessDto extends GradeDto {
}

export interface EditGradeDto extends GradeDto {
}

export interface EditGradeSuccessDto extends GradeDto {
}

export interface EditMultipleGradeDto extends OmitStrict<GradeDto, "id"> {
  id: GradeDto["id"] | null;
}

export interface EditMultipleGradesDto {
  dayId: number;
  tellsyActivityId: string;
  tellsyParticipantId: string;
  tellsyUserName: string;
  grades: EditMultipleGradeDto[];
}

export interface EditMultipleGradesSuccessDto {
  grades: GradeDto[];
  summary: GradesSummary[];
}

export interface CreateGradeForMultipleParticipantDto {
  gradeTypeId: GradeTypeDto["id"];
  dayId: DayDto["id"];
  tellsyActivityId: TellsyActivityDto["activityId"];
  score: number;
  participants: {
    tellsyParticipantId: TellsyParticipantDto["participantId"];
    tellsyUserName: TellsyParticipantDto["username"];
  }[];
}

export interface CreateGradeForMultipleParticipantSuccessDto {
  grades: GradeDto[];
  summary: GradesSummary[];
}

export type DeleteGradeDto = GradeDto["id"];

export type DeleteGradeSuccessDto = GradeDto["id"];

export interface GetAllGradesByActivityAndUserNameDto {
  tellsyActivityId: TellsyActivityDto["activityId"];
  tellsyUsername: TellsyParticipantDto["username"];
}

export type GetAllGradesByActivityAndUserNameSuccessDto = GradeDto[];

export interface GetGradesByActivityAndParticipantIdDto {
  tellsyActivityId: TellsyActivityDto["activityId"];
  tellsyParticipantId: TellsyParticipantDto["participantId"];
}

export type GetGradesByActivityAndParticipantIdSuccessDto = GradeDto[];

export type GetAutoGradesDto = DayDto["id"];

export type AutoGradeTypeTitle =
  | "ASK_QUESTION_AT_LEAST_1"
  | "ASK_QUESTION_TOP_5_LIKED"
  | "GATHER_OPINIONS_AT_LEAST_1"
  | "GATHER_OPINIONS_TOP_5_LIKED"
  | "SURVEY_PARTICIPATION"
  | "FIRST_PARTICIPANT_TO_HIT_ACTIVITY"
  | "TEST_CORRECT_ANSWERS_N"
  | "TEST_CORRECT_ANSWERS_N+M"
  | "QUIZ_CORRECT_ANSWERS_N"
  | "QUIZ_CORRECT_ANSWERS_N+M"
  | GeneralAutoGradeTypeTitle;

export type GeneralAutoGradeTypeTitle =
  | "PARTICIPANT_LOGIN_TELLSY"
  | "MANAGEMENT_ASSESSMENT"
  | "PARTICIPANT_WP_AVATAR_UPLOAD";

export const generalAutoGradeTypesName: Record<GeneralAutoGradeTypeTitle,
  string> = {
  PARTICIPANT_LOGIN_TELLSY: "Вошёл в сессию Tellsy",
  PARTICIPANT_WP_AVATAR_UPLOAD: "Загрузил аватар",
  MANAGEMENT_ASSESSMENT: "Оценка от менеджера",
};

export interface AutoGradeParticipantDto {
  tellsyParticipantId: string;
  tellsyUserName: string;
  autoScoreBreakdown: ScoreItem[];
  dayId: DayDto["id"];
}

export interface ScoreItem {
  autoGradeTypeTitle: AutoGradeTypeTitle;
  autoGradeTypeDescription: string;
  tellsyActivityId: string | null;
  tellsyActivityName: string | null;
  score: number;
}

export type GetAutoGradesSuccessDto = AutoGradeParticipantDto[];

export interface EditAutoGradeTypeDto
  extends OmitStrict<AutoGradeTypeDto,
    "id" | "activityId" | "dayId" | "description" | "chosenTitle" | "score"> {
  id: AutoGradeTypeDto["id"] | null;
  activityId: string | null;
  chosenTitle: AutoGradeTypeTitle | null;
  score: number | null;
}

export interface EditAutoGradeTypesDto {
  lineId: LineDto["id"];
  dayId: DayDto["id"];
  autoGradeTypes: EditAutoGradeTypeDto[];
}

export type EditAutoGradeTypesSuccessDto = AutoGradeTypeDto[];

export interface AllowedAutoGradeTypesDto {
  activityMode: ActivityTypeDto;
  autoGradeTitleToDescriptionMap: Record<AutoGradeTypeTitle, string>;
}

export type GetAllowedAutoGradeTypesSuccessDto = AllowedAutoGradeTypesDto[];

export interface GetLineReportDataSuccessDto {
  manualGrades: GradesSummary[];
  autoGrades: AutoGradeParticipantDto[];
  line: LineDto;
  participants: TellsyParticipantDto[];
  excludedParticipantsDto: ExcludedParticipantsDto;
}
