import { createReducer, on } from "@ngrx/store";
import { User } from "@store/auth/model";
import { AuthActions } from "../actions";

export const AUTH_STATE_ID = "auth";

export interface AuthState {
  user: User | null;
  error: string | null;
  pending: boolean;
}

export const initialState: AuthState = {
  user: null,
  error: null,
  pending: false,
};

export const reducer = createReducer<AuthState>(
  initialState,

  on(
    AuthActions.loadUser,
    (state): AuthState => ({
      ...state,
      pending: true,
    })
  ),

  on(
    AuthActions.loadUserSuccess,
    (state, { user }): AuthState => ({
      ...state,
      user,
      pending: false,
    })
  ),

  on(
    AuthActions.loadUserFail,
    (state): AuthState => ({
      ...state,
      pending: false,
    })
  ),

  on(
    AuthActions.loadTokenByCredentials,
    (state): AuthState => ({
      ...state,
      pending: true,
    })
  ),

  on(
    AuthActions.loadTokenByCredentialsSuccess,
    (state): AuthState => ({
      ...state,
      pending: false,
      error: null,
    })
  ),

  on(
    AuthActions.loadTokenByCredentialsFail,
    (state, { dto: response }): AuthState => {
      let error = `Неизвестная ошибка (${response.error})`;
      if (response.error === "invalid_grant") {
        error = `Неверный логин или пароль.`;

        if (response.attemptsRemaining !== null) {
          error += ` Осталось попыток: ${response.attemptsRemaining}`;
        }
      }
      return {
        ...state,
        error,
        pending: false,
      };
    }
  )
);
