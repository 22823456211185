import { createSelector } from "@ngrx/store";
import { selectProjectsState } from "@store/projects/projects.module";
import { tellsyParticipantsSelectors } from "@store/projects/reducers/participants.reducer";

const selectTellsyParticipantsState = createSelector(
  selectProjectsState,
  (state) => state.tellsyParticipants
);

const selectAllTellsyParticipants = createSelector(
  selectTellsyParticipantsState,
  tellsyParticipantsSelectors.selectAll
);

const selectIsLoading = createSelector(
  selectTellsyParticipantsState,
  (state) => state.isLoading
);

export const TellsyParticipantsSelectors = {
  selectAllTellsyParticipants,
  selectIsLoading,
};
