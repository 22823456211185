import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ProjectsActions, TellsyParticipantsActions } from "@store/projects/actions/";
import { TellsyParticipantsService } from "@store/projects/services";
import { map, switchMap } from "rxjs";

@Injectable()
export class TellsyParticipantsEffects {
  loadTellsyParticipants = createEffect(() => {
    return this.actions.pipe(
      ofType(TellsyParticipantsActions.loadTellsyParticipants),
      switchMap(({ eventId }) => this.service.loadTellsyParticipants(eventId)),
      map((dto) =>
        TellsyParticipantsActions.loadTellsyParticipantsSuccess({ dto })
      )
    );
  });

  updateExcludedTellsyParticipants = createEffect(() => {
    return this.actions.pipe(
      ofType(TellsyParticipantsActions.updateExcludedTellsyParticipants),
      switchMap((dto) => this.service.updateExcludedTellsyParticipants(dto)),
      map((dto) =>
        ProjectsActions.updateExcludedTellsyParticipantsSuccess(dto)
      )
    );
  });

  constructor(
    private actions: Actions,
    private service: TellsyParticipantsService
  ) {
  }
}
