import { createReducer, on } from "@ngrx/store";

import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { LineDto } from "@store/projects/model/dto/line.dto";
import { ProjectDto } from "@store/projects/model/dto/projects.dto";
import { ProjectsProjectsState } from "@store/projects/reducers";
import { ProjectsActions } from "../actions";

export const projectsAdapter: EntityAdapter<ProjectDto> =
  createEntityAdapter<ProjectDto>();
export const linesAdapter: EntityAdapter<LineDto> =
  createEntityAdapter<LineDto>();

export const initialState: ProjectsProjectsState =
  projectsAdapter.getInitialState({
    isLoading: {
      loadingProjectsPending: false,
      addProjectPending: false,
      updateProjectPending: false,
      deleteProjectPending: false,
    },
  });

export const projectsReducer = createReducer<ProjectsProjectsState>(
  initialState,

  on(
    ProjectsActions.loadProjects,
    (state): ProjectsProjectsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        loadingProjectsPending: true,
      },
    })
  ),

  on(
    ProjectsActions.loadProjectsSuccess,
    (state, { dto }): ProjectsProjectsState =>
      projectsAdapter.setAll(dto, {
        ...state,
        isLoading: {
          ...state.isLoading,
          loadingProjectsPending: false,
        },
      })
  ),

  on(
    ProjectsActions.loadProjectSuccess,
    (state, { dto }): ProjectsProjectsState =>
      projectsAdapter.setOne(dto, state)
  ),

  on(
    ProjectsActions.addProject,
    (state): ProjectsProjectsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        addProjectPending: true,
      },
    })
  ),

  on(
    ProjectsActions.addProjectSuccess,
    (state, { dto }): ProjectsProjectsState =>
      projectsAdapter.addOne(dto, {
        ...state,
        isLoading: {
          ...state.isLoading,
          addProjectPending: false,
        },
      })
  ),

  on(
    ProjectsActions.updateProject,
    (state): ProjectsProjectsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        updateProjectPending: true,
      },
    })
  ),

  on(
    ProjectsActions.updateProjectSuccess,
    (state, { dto }): ProjectsProjectsState =>
      projectsAdapter.updateOne(
        { id: dto.id, changes: dto },
        {
          ...state,
          isLoading: {
            ...state.isLoading,
            updateProjectPending: false,
          },
        }
      )
  ),

  on(
    ProjectsActions.deleteProject,
    (state): ProjectsProjectsState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        deleteProjectPending: true,
      },
    })
  ),

  on(
    ProjectsActions.deleteProjectSuccess,
    (state, { dto }): ProjectsProjectsState =>
      projectsAdapter.removeOne(dto, {
        ...state,
        isLoading: {
          ...state.isLoading,
          deleteProjectPending: false,
        },
      })
  )
);

export const projectsSelectors = projectsAdapter.getSelectors(
  (state: ProjectsProjectsState) => state
);
