import { createSelector } from "@ngrx/store";
import { ProjectDto } from "@store/projects/model/dto/projects.dto";
import { selectProjectsState } from "@store/projects/projects.module";
import { projectsSelectors } from "@store/projects/reducers/projects.reducer";

const selectProjectsProjectsState = createSelector(
  selectProjectsState,
  (state) => state.projects
);

const selectAllProjects = createSelector(
  selectProjectsProjectsState,
  projectsSelectors.selectAll
);

const selectAllProjectsEntities = createSelector(
  selectProjectsProjectsState,
  projectsSelectors.selectEntities
);

const selectProject = (id: ProjectDto["id"]) =>
  createSelector(
    selectAllProjectsEntities,
    (projectsEntities) => projectsEntities[id]
  );

const selectLoadingState = createSelector(
  selectProjectsProjectsState,
  (state) => state?.isLoading
);

const selectIsLoadingProjectsPending = createSelector(
  selectLoadingState,
  (state) => state?.loadingProjectsPending
);

const selectIsAddProjectPending = createSelector(
  selectLoadingState,
  (state) => state?.addProjectPending
);

const selectIsUpdateProjectPending = createSelector(
  selectLoadingState,
  (state) => state?.updateProjectPending
);

const selectIsDeleteProjectPending = createSelector(
  selectLoadingState,
  (state) => state?.deleteProjectPending
);

export const ProjectsSelectors = {
  selectAllProjects,
  selectAllProjectsEntities,
  selectProject,
  selectIsLoadingProjectsPending,
  selectIsAddProjectPending,
  selectIsUpdateProjectPending,
  selectIsDeleteProjectPending,
};
