import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "@shared/api.config";
import {
  LoadTellsyActivitiesDto,
  LoadTellsyActivitiesSuccessDto,
} from "@store/projects/model/dto/tellsy-activities.dto";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TellsyActivitiesService {
  constructor(private http: HttpClient) {}

  loadTellsyActivities(
    dto: LoadTellsyActivitiesDto
  ): Observable<LoadTellsyActivitiesSuccessDto> {
    const url = API.tellsyActivities.loadActivities();
    const params = {
      eventIds: dto.eventIds,
    };

    if (!dto.eventIds.length) {
      return of([]);
    }

    return this.http.get<LoadTellsyActivitiesSuccessDto>(url, { params });
  }
}
