import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "@shared/api.config";
import { User } from "@store/auth/model";
import { LoadTokenSuccessDto } from "@store/auth/model/dto/auth.dto";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) {}

  loadUser(): Observable<User> {
    const url = API.auth.loadUser();
    return this.http.get<User>(url);
  }

  // This method accepts either refreshToken, or username + password to fetch new access token
  loadToken(
    data:
      | {
          refreshToken: string;
        }
      | { username: string; password: string }
  ): Observable<LoadTokenSuccessDto> {
    const url = API.auth.requestAuthToken();
    let body = new HttpParams();

    if ("refreshToken" in data) {
      body = body
        .set("grant_type", "refresh_token")
        .set("refresh_token", data.refreshToken);
    }

    if ("username" in data && "password" in data) {
      body = body
        .set("username", data.username)
        .set("password", data.password)
        .set("grant_type", "password");
    }

    const options = {
      headers: new HttpHeaders({
        "Authorization": "Basic " + this.encodeToBase46("moderator:secret"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };

    return this.http.post<LoadTokenSuccessDto>(url, body.toString(), options);
  }

  private encodeToBase46(text: string): string {
    return window?.btoa(text);
  }
}
