import { Injectable, Signal } from "@angular/core";
import { Store } from "@ngrx/store";
import { AuthActions } from "@store/auth/actions";
import { User } from "@store/auth/model";
import { AuthSelectors } from "@store/auth/selectors";
import { AuthStorageService } from "@store/auth/services/";

@Injectable({
  providedIn: "root",
})
export class AuthFacade {
  constructor(private authStorage: AuthStorageService, private store: Store) {}

  loadUser(): void {
    this.store.dispatch(AuthActions.loadUser());
  }

  loadTokenByCredentials(username: string, password: string): void {
    this.store.dispatch(
      AuthActions.loadTokenByCredentials({ username, password })
    );
  }

  // TODO move to effect or remove
  logout(): void {
    this.authStorage.clean();
    window.location.reload();
  }

  // selectors

  selectUser(): Signal<User | null> {
    return this.store.selectSignal(AuthSelectors.selectUser);
  }

  selectError(): Signal<string | null> {
    return this.store.selectSignal(AuthSelectors.selectError);
  }

  selectPending(): Signal<boolean> {
    return this.store.selectSignal(AuthSelectors.selectPending);
  }
}
