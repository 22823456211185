import { Injectable, Signal } from "@angular/core";
import { Dictionary } from "@ngrx/entity";
import { Store } from "@ngrx/store";
import { DayDto } from "@store/projects/model/dto/day.dto";
import { LineDto } from "@store/projects/model/dto/line.dto";
import { TellsyActivityDto } from "@store/projects/model/dto/tellsy-activities.dto";
import { ProjectsCombinedSelectors } from "@store/projects/selectors/combined.selectors";
import { LinesSelectors } from "@store/projects/selectors/lines.selectors";

@Injectable({
  providedIn: "root",
})
export class LinesFacade {
  constructor(private store: Store) {
  }

  selectAllLines(): Signal<LineDto[]> {
    return this.store.selectSignal(LinesSelectors.selectAllLines);
  }

  selectCurrentLine(): Signal<LineDto | undefined> {
    return this.store.selectSignal(ProjectsCombinedSelectors.selectCurrentLine);
  }

  selectCurrentDay(): Signal<DayDto | undefined> {
    return this.store.selectSignal(ProjectsCombinedSelectors.selectCurrentDay);
  }

  selectLine(id: LineDto["id"]): Signal<LineDto | undefined> {
    return this.store.selectSignal(LinesSelectors.selectLine(id));
  }

  selectDay(
    lineId: LineDto["id"],
    dayId: DayDto["id"]
  ): Signal<DayDto | undefined> {
    return this.store.selectSignal(LinesSelectors.selectDay(lineId, dayId));
  }

  selectLineDays(id: LineDto["id"]): Signal<DayDto[] | undefined> {
    return this.store.selectSignal(LinesSelectors.selectLineDays(id));
  }

  selectAllNotExcludedActivitiesForCurrentLine(): Signal<TellsyActivityDto[]> {
    return this.store.selectSignal(
      ProjectsCombinedSelectors.selectAllNotExcludedActivitiesForCurrentLine
    );
  }

  selectAllNotExcludedActivitiesEntitiesForCurrentLine(): Signal<Dictionary<TellsyActivityDto>> {
    return this.store.selectSignal(
      ProjectsCombinedSelectors.selectAllNotExcludedActivitiesEntitiesForCurrentLine
    );
  }

}
