import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "@shared/api.config";
import { LoadParticipantsSuccessDto } from "@store/projects/model/dto/tellsy-participant.dto";
import { Observable } from "rxjs";
import { ExcludedParticipantsDto } from "@store/projects/model/dto/line.dto";

@Injectable({
  providedIn: "root",
})
export class TellsyParticipantsService {
  constructor(private http: HttpClient) {
  }

  loadTellsyParticipants(
    eventId: string
  ): Observable<LoadParticipantsSuccessDto> {
    const url = API.tellsyParticipants.loadParticipants();

    const params = {
      eventId,
    };

    return this.http.get<LoadParticipantsSuccessDto>(url, {
      params,
    });
  }

  updateExcludedTellsyParticipants(dto: ExcludedParticipantsDto): Observable<ExcludedParticipantsDto> {
    const url = API.lines.updateExcludedParticipants(dto.lineId);
    return this.http.put<ExcludedParticipantsDto>(url, dto.tellsyExcludedParticipantIds);
  }
}
