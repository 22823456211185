import { Injectable } from "@angular/core";
import { StorageService } from "@shared/services/storage.service";

export interface UserCredentials {
  access_token: string;
  refresh_token: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthStorageService {
  readonly CredentialsKey = "AUTH_CREDENTIALS";

  constructor(private storage: StorageService) {}

  storeCredentials(credentials: UserCredentials): void {
    this.storage.setItem(this.CredentialsKey, JSON.stringify(credentials));
  }

  getCredentials(): UserCredentials | null {
    return this.getCredentialsObj(this.CredentialsKey);
  }

  removeCredentials(): void {
    this.storage.removeItem(this.CredentialsKey);
  }

  clean(): void {
    this.storage.removeItem(this.CredentialsKey);
  }

  private getCredentialsObj(key: string): UserCredentials | null {
    const credentialsStringified = this.storage.getItem(key);

    return credentialsStringified ? JSON.parse(credentialsStringified) : null;
  }
}
