import { createSelector } from "@ngrx/store";
import { TellsyActivityDto } from "@store/projects/model/dto/tellsy-activities.dto";
import { TellsyParticipantDto } from "@store/projects/model/dto/tellsy-participant.dto";
import { selectProjectsState } from "@store/projects/projects.module";
import {
  allowedAutoGradesTypesSelectors,
  autoGradesSelectors,
  gradesSelectors,
  gradesSummarySelectors,
  gradesTypesSelectors,
} from "@store/projects/reducers/grades.reducer";

const selectGradesState = createSelector(
  selectProjectsState,
  (state) => state.grades
);

const selectAllGradesTypes = createSelector(
  selectGradesState,
  gradesTypesSelectors.selectAll
);

const selectGradesTypesEntities = createSelector(
  selectGradesState,
  gradesTypesSelectors.selectEntities
);

const selectAllGrades = createSelector(
  selectGradesState,
  gradesSelectors.selectAll
);

const selectGradesEntities = createSelector(
  selectGradesState,
  gradesSelectors.selectEntities
);

const selectGradesSummaryEntities = createSelector(
  selectGradesState,
  gradesSummarySelectors.selectEntities
);

const selectIsGradesSummaryLoading = createSelector(
  selectGradesState,
  (state) => state.isLoading.gradesSummary
);

const selectIsAutoGradesLoading = createSelector(
  selectGradesState,
  (state) => state.isLoading.autoGrades
);

const selectAllAutoGrades = createSelector(
  selectGradesState,
  autoGradesSelectors.selectAll
);

const selectAutoGradesEntities = createSelector(
  selectGradesState,
  autoGradesSelectors.selectEntities
);

const selectGradesByUserNameAndActivityId = (
  username: TellsyParticipantDto["username"],
  activityId: TellsyActivityDto["activityId"]
) =>
  createSelector(selectAllGrades, (grades) =>
    grades.filter(
      ({ tellsyUserName, tellsyActivityId }) =>
        tellsyUserName === username && tellsyActivityId === activityId
    )
  );

const selectAllAllowedAutoGradesTypes = createSelector(
  selectGradesState,
  allowedAutoGradesTypesSelectors.selectAll
);

const selectAllAllowedAutoGradesTypesEntities = createSelector(
  selectGradesState,
  allowedAutoGradesTypesSelectors.selectEntities
);

const selectIsReportLoading = createSelector(
  selectGradesState,
  (state) => state.isLoading.report
);

export const GradesSelectors = {
  selectAllGradesTypes,
  selectGradesTypesEntities,
  selectAllGrades,
  selectGradesEntities,
  selectGradesSummaryEntities,
  selectGradesByUserNameAndActivityId,
  selectIsGradesSummaryLoading,
  selectIsAutoGradesLoading,
  selectAllAutoGrades,
  selectAutoGradesEntities,
  selectAllAllowedAutoGradesTypes,
  selectAllAllowedAutoGradesTypesEntities,
  selectIsReportLoading,
};
