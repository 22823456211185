import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private storage: Storage | null;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    if (isPlatformBrowser(this.platformId)) {
      this.storage = localStorage;
    } else {
      this.storage = null;
    }
  }

  clear(): void {
    this.storage?.clear();
  }

  getItem(key: string): string | null | undefined {
    return this.storage?.getItem(key);
  }

  key(index: number): string | null | undefined {
    return this.storage?.key(index);
  }

  removeItem(key: string): void {
    return this.storage?.removeItem(key);
  }

  setItem(key: string, value: string): void {
    return this.storage?.setItem(key, value);
  }
}
