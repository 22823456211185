import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ExcelReportUtils } from "@shared/utils/excel-report";
import { GradesActions } from "@store/projects/actions";
import { GradesService } from "@store/projects/services/grades.service";
import { catchError, map, of, switchMap, tap } from "rxjs";

@Injectable()
export class GradesEffects {
  loadGradeTypesByLineId = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.loadGradeTypesByLineId),
      switchMap(({ dto }) => this.service.loadGradeTypesByLineId(dto)),
      map((dto) => GradesActions.loadGradeTypesByLineIdSuccess({ dto }))
    );
  });

  createGradeType = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.createGradeType),
      switchMap(({ dto }) => this.service.createGradeType(dto)),
      map((dto) => GradesActions.createGradeTypeSuccess({ dto }))
    );
  });

  createGradeTypes = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.createGradeTypes),
      switchMap(({ dto }) => this.service.createGradeTypes(dto)),
      map((dto) => GradesActions.createGradeTypesSuccess({ dto }))
    );
  });

  editGradeTypes = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.editGradeTypes),
      switchMap(({ dto }) => this.service.editGradeTypes(dto)),
      map((dto) => GradesActions.editGradeTypesSuccess({ dto }))
    );
  });

  editGradeTypesSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(GradesActions.editGradeTypesSuccess),
        tap(() =>
          this.snackBar.open("Типы оценок сохранены", "ОК", {
            duration: 3000,
          })
        )
      );
    },
    { dispatch: false }
  );

  deleteGradeType = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.deleteGradeType),
      switchMap(({ dto }) => this.service.deleteGradeType(dto)),
      map((dto) => GradesActions.deleteGradeTypeSuccess({ dto }))
    );
  });

  loadGradesForDay = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.loadGradesSummaryForDay),
      switchMap(({ dto }) => this.service.loadGradesSummaryForDay(dto)),
      map((dto) => GradesActions.loadGradesSummaryForDaySuccess({ dto }))
    );
  });

  createGrade = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.createGrade),
      switchMap(({ dto }) => this.service.createGrade(dto)),
      map((dto) => GradesActions.createGradeSuccess({ dto }))
    );
  });

  editGrade = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.editGrade),
      switchMap(({ dto }) => this.service.editGrade(dto)),
      map((dto) => GradesActions.editGradeSuccess({ dto }))
    );
  });

  editMultipleGrades = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.editMultipleGrades),
      switchMap(({ dto }) => this.service.editMultipleGrades(dto)),
      map((dto) => GradesActions.editMultipleGradesSuccess({ dto }))
    );
  });

  createGradeForMultipleParticipant = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.createGradeForMultipleParticipant),
      switchMap(({ dto }) =>
        this.service.createGradeForMultipleParticipant(dto)
      ),
      map((dto) =>
        GradesActions.createGradeForMultipleParticipantSuccess({ dto })
      )
    );
  });

  deleteGrade = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.deleteGrade),
      switchMap(({ dto }) => this.service.deleteGrade(dto)),
      map((dto) => GradesActions.deleteGradeSuccess({ dto }))
    );
  });

  loadAllGradesByActivityAndUsername = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.loadAllGradesByActivityAndUserName),
      switchMap(({ dto }) =>
        this.service.loadAllGradesByActivityAndUserName(dto)
      ),
      map((dto) =>
        GradesActions.loadAllGradesByActivityAndUserNameSuccess({ dto })
      )
    );
  });

  loadGradesSumByActivityAndParticipantId = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.loadGradesByActivityAndParticipantId),
      switchMap(({ dto }) =>
        this.service.loadGradesByActivityAndParticipantId(dto)
      ),
      map((dto) =>
        GradesActions.loadGradesByActivityAndParticipantIdSuccess({ dto })
      )
    );
  });

  loadAutoGrades = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.loadAutoGrades),
      switchMap(({ dto }) => this.service.loadAutoGrades(dto)),
      map((dto) => GradesActions.loadAutoGradesSuccess({ dto }))
    );
  });

  editAutoGradeTypes = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.editAutoGradeTypes),
      switchMap(({ dto }) =>
        this.service.editAutoGradeTypesByDayId(dto).pipe(
          map((autoGradeTypes) => ({
            lineId: dto.lineId,
            dayId: dto.dayId,
            dto: autoGradeTypes,
          }))
        )
      ),
      map(({ lineId, dayId, dto }) =>
        GradesActions.editAutoGradeTypesSuccess({ lineId, dayId, dto })
      )
    );
  });

  editAutoGradeTypesSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.editAutoGradeTypesSuccess),
      tap(() =>
        this.snackBar.open("Автооценки сохранены", "ОК", {
          duration: 3000,
        })
      ),
      map(({ dto }) => GradesActions.loadAutoGrades({ dto: dto[0].dayId }))
    );
  });

  loadAllowedAutoGradeTypes = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.loadAllowedAutoGradeTypes),
      switchMap(() => this.service.getAllowedAutoGradeTypes()),
      map((dto) => GradesActions.loadAllowedAutoGradeTypesSuccess({ dto }))
    );
  });

  loadProjectExcelReport = createEffect(() => {
    return this.actions.pipe(
      ofType(GradesActions.loadProjectExcelReport),
      switchMap(({ lineId, activities, projectTitle, teams }) =>
        this.service.getLineReportData(lineId).pipe(
          map((data) => ({
            ...data,
            activities,
            projectTitle,
            teams,
          }))
        )
      ),
      tap((data) => ExcelReportUtils.saveExcelReport(data)),
      map(() => GradesActions.loadProjectExcelReportSuccess()),
      catchError((error) => {
        console.error("Error while saving excel report!", error);
        return of(GradesActions.loadProjectExcelReportFail({ error }));
      })
    );
  });

  constructor(
    private actions: Actions,
    private service: GradesService,
    private snackBar: MatSnackBar
  ) {}
}
