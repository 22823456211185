import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { TellsyParticipantsActions } from "@store/projects/actions";
import { TellsyParticipantDto } from "@store/projects/model/dto/tellsy-participant.dto";
import { TellsyParticipantsState } from "@store/projects/reducers";

export const tellsyParticipantsAdapter: EntityAdapter<TellsyParticipantDto> =
  createEntityAdapter<TellsyParticipantDto>({
    selectId: (participant) => participant.participantId,
    sortComparer: (a, b) => a.identifier.localeCompare(b.identifier),
  });

export const initialState: TellsyParticipantsState =
  tellsyParticipantsAdapter.getInitialState({ isLoading: false });

export const tellsyParticipantsReducer = createReducer<TellsyParticipantsState>(
  initialState,

  on(
    TellsyParticipantsActions.loadTellsyParticipants,
    (state): TellsyParticipantsState => ({ ...state, isLoading: true })
  ),
  on(
    TellsyParticipantsActions.loadTellsyParticipantsSuccess,
    (state, { dto }): TellsyParticipantsState =>
      tellsyParticipantsAdapter.setAll(dto, { ...state, isLoading: false })
  )
);

export const tellsyParticipantsSelectors =
  tellsyParticipantsAdapter.getSelectors(
    (state: TellsyParticipantsState) => state
  );
