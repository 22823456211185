import { createSelector } from "@ngrx/store";
import { selectProjectsState } from "@store/projects/projects.module";
import { tellsyTeamsSelectors } from "@store/projects/reducers/tellsy-teams.reducer";

const selectTellsyTeamsState = createSelector(
  selectProjectsState,
  (state) => state.tellsyTeams
);

const selectAllTellsyTeams = createSelector(
  selectTellsyTeamsState,
  tellsyTeamsSelectors.selectAll
);

const selectIsTeamsLoading = createSelector(
  selectTellsyTeamsState,
  (state) => state.isLoading
);

export const TellsyTeamsSelectors = {
  selectAllTellsyTeams,
  selectIsTeamsLoading,
};
