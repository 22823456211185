import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { TellsyTeamsActions } from "@store/projects/actions/";
import { TellsyTeamDto } from "@store/projects/model/dto/team.dto";
import { TellsyTeamsState } from "@store/projects/reducers";

export const tellsyTeamsAdapter: EntityAdapter<TellsyTeamDto> =
  createEntityAdapter<TellsyTeamDto>({
    sortComparer: (a, b) =>
      a.teamName.localeCompare(b.teamName, undefined, { numeric: true }),
  });

export const initialState: TellsyTeamsState =
  tellsyTeamsAdapter.getInitialState({ isLoading: false });

export const tellsyTeamsReducer = createReducer<TellsyTeamsState>(
  initialState,

  on(
    TellsyTeamsActions.loadTellsyTeams,
    (state): TellsyTeamsState => ({ ...state, isLoading: true })
  ),

  on(
    TellsyTeamsActions.loadTellsyTeamsSuccess,
    (state, { dto }): TellsyTeamsState =>
      tellsyTeamsAdapter.setAll(dto, { ...state, isLoading: false })
  )
);

export const tellsyTeamsSelectors = tellsyTeamsAdapter.getSelectors(
  (state: TellsyTeamsState) => state
);
