import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { User } from "@store/auth/model";
import {
  LoadTokenFailDto,
  LoadTokenSuccessDto,
} from "@store/auth/model/dto/auth.dto";

export const AuthActions = createActionGroup({
  source: "Auth",
  events: {
    "Load User": emptyProps(),
    "Load User Success": props<{ user: User }>(),
    "Load User Fail": props<{ error?: string }>(),

    "Load Token By Credentials": props<{
      username: string;
      password: string;
    }>(),
    "Load Token By Credentials Success": props<{
      dto: LoadTokenSuccessDto;
    }>(),
    "Load Token By Credentials Fail": props<{
      dto: LoadTokenFailDto;
    }>(),
  },
});
