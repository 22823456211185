import { DayDto } from "@store/projects/model/dto/day.dto";
import { TellsyTeamDto } from "@store/projects/model/dto/team.dto";
import { ParticipantWithGradesSummaryViewModel } from "@store/projects/model/tellsy-participant-with-grades-summary.view-model";
import { ExcludedParticipantsDto } from "@store/projects/model/dto/line.dto";

export interface TeamsWithParticipantsViewModel extends TellsyTeamDto {
  participants: ParticipantWithGradesSummaryViewModel[];
  participantsCount: number;
  dailyTotalScore: number;
}

export const convertToTeamsWithParticipantsViewModel = ({
  teams,
  participants,
  excludedParticipantsDto
}: {
  day: DayDto;
  teams: TellsyTeamDto[];
  participants: ParticipantWithGradesSummaryViewModel[];
  excludedParticipantsDto: ExcludedParticipantsDto
}): TeamsWithParticipantsViewModel[] => {
  const participantsByTeams: Record<
    string,
    ParticipantWithGradesSummaryViewModel[]
  > = participants.filter((participant) => !excludedParticipantsDto?.tellsyExcludedParticipantIds.includes(participant.participantId)).reduce((accumulator, participant) => {
    if (!participant.teamId) {
      return accumulator;
    }

    if (accumulator[participant.teamId]?.length > 0) {
      return {
        ...accumulator,
        [participant.teamId]: [...accumulator[participant.teamId], participant],
      };
    }

    return { ...accumulator, [participant.teamId]: [participant] };
  }, {} as Record<string, ParticipantWithGradesSummaryViewModel[]>);

  return teams.filter((team) => !!participantsByTeams[team.id]).map((team) => ({
    ...team,
    participants: participantsByTeams[team.id],
    participantsCount: participantsByTeams[team.id]?.length,
    dailyTotalScore: participantsByTeams[team.id]
      ? participantsByTeams[team.id].reduce(
          (sum, p) => p.dailyTotalScore + sum,
          0
        )
      : 0,
  }));
};
