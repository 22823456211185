import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule, createFeatureSelector } from "@ngrx/store";
import {
  GradesEffects,
  ProjectsEffects,
  TellsyActivitiesEffects,
  TellsyEventsEffects,
  TellsyParticipantsEffects,
  TellsyTeamsEffects,
} from "@store/projects/effects";
import {
  PROJECTS_STATE_ID,
  ProjectsState,
  projectsReducers,
} from "@store/projects/reducers";

export const selectProjectsState =
  createFeatureSelector<ProjectsState>(PROJECTS_STATE_ID);

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(PROJECTS_STATE_ID, projectsReducers),
    EffectsModule.forFeature([
      ProjectsEffects,
      GradesEffects,
      TellsyEventsEffects,
      TellsyParticipantsEffects,
      TellsyActivitiesEffects,
      TellsyTeamsEffects,
    ]),
  ],
})
export class ProjectsStoreModule {}
