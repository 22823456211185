import { Dictionary } from "@ngrx/entity";
import { convertToTeamsWithParticipantsViewModel, TeamsWithParticipantsViewModel, } from "@store/projects/model";
import { DayDto } from "@store/projects/model/dto/day.dto";
import {
  AutoGradeParticipantDto,
  generalAutoGradeTypesName,
  GeneralAutoGradeTypeTitle,
  GetLineReportDataSuccessDto,
  GradesSummary,
} from "@store/projects/model/dto/grade.dto";
import { ExcludedParticipantsDto, LineDto } from "@store/projects/model/dto/line.dto";
import { ProjectDto } from "@store/projects/model/dto/projects.dto";
import { TellsyTeamDto } from "@store/projects/model/dto/team.dto";
import { TellsyActivityDto } from "@store/projects/model/dto/tellsy-activities.dto";
import { TellsyParticipantDto } from "@store/projects/model/dto/tellsy-participant.dto";
import {
  convertToParticipantWithGradesSummaryViewModel,
  ParticipantWithGradesSummaryViewModel,
} from "@store/projects/model/tellsy-participant-with-grades-summary.view-model";
import { Workbook } from "exceljs";
import * as fs from "file-saver";

export interface ExcelReportData extends GetLineReportDataSuccessDto {
  activities: TellsyActivityDto[];
  projectTitle: ProjectDto["title"];
  teams: TellsyTeamDto[];
}

function saveExcelReport(data: ExcelReportData): void {
  const {
    projectTitle,
    line,
    manualGrades,
    participants,
    autoGrades,
    activities,
    excludedParticipantsDto
  } = data;

  //Create workbook and add worksheets
  const workbook = new Workbook();

  const participantsViewModelForEachDay = getParticipantsViewModelForEachDay(
    line,
    manualGrades,
    participants,
    autoGrades,
    activities,
    excludedParticipantsDto
  );

  addRatingWorksheet(workbook, data, participantsViewModelForEachDay);
  addTeamsRatingWorksheet(workbook, data, participantsViewModelForEachDay);

  //Generate Excel File with given name
  workbook.xlsx.writeBuffer().then((bufferData) => {
    const blob = new Blob([bufferData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(
      blob,
      `Отчёт по линии ${line.title} в проекте ${projectTitle}.xlsx`
    );
  });
}

function addRatingWorksheet(
  workbook: Workbook,
  { line, activities, participants, autoGrades, excludedParticipantsDto }: ExcelReportData,
  participantsViewModelForEachDay: Record<DayDto["id"],
    ParticipantWithGradesSummaryViewModel[]>
): void {
  const worksheet = workbook.addWorksheet("Рейтинг индивидуальный", {
    views: [{ state: "frozen", ySplit: 2, xSplit: 4 }],
  });

  const activitiesOfAutoGradesPerDay: Record<DayDto["id"], string[]> = getActivitiesOfAutoGradesPerDay(line.days);

  function filterActivitiesByDay(dayId: DayDto["id"]): TellsyActivityDto[] {
    return activities.filter((activity) => !!activity && activitiesOfAutoGradesPerDay[dayId].includes(activity.activityId));
  }

  // all auto grades without activityId (general grades)
  const uniqueGradesWithoutActivityIdTypes: GeneralAutoGradeTypeTitle[] = [
    ...autoGrades.reduce((resultSet, autoGrade) => {
      autoGrade.autoScoreBreakdown
        .filter(
          (autoScore) =>
            autoScore.autoGradeTypeTitle !== "MANAGEMENT_ASSESSMENT"
        )
        .forEach((autoScoreBreakdown) => {
          if (!autoScoreBreakdown.tellsyActivityId) {
            resultSet.add(
              autoScoreBreakdown.autoGradeTypeTitle as GeneralAutoGradeTypeTitle
            );
          }
        });
      return resultSet;
    }, new Set<GeneralAutoGradeTypeTitle>()),
  ];

  const managerScoresHeadersForEachDay: Record<DayDto["id"], string[]> =
    line.days.reduce(
      (result, day) => ({
        ...result,
        [day.id]: [
          ...participantsViewModelForEachDay[day.id].reduce((resultSet, p) => {
            p.autoGradesFromManagers.forEach((managerGrade) => {
              if (managerGrade.tellsyActivityName && managerGrade.score > 0) {
                resultSet.add(managerGrade.tellsyActivityName);
              }
            });

            return resultSet;
          }, new Set<string>([])),
        ],
      }),
      {} as Record<DayDto["id"], string[]>
    );

  const participantsTotalScore = new Map<TellsyParticipantDto["username"],
    number>();

  const participantsDailyGradesRow: Record<TellsyParticipantDto["username"],
    (string | number)[]> = line.days.reduce((result, day) => {
    participantsViewModelForEachDay[day.id].forEach((p) => {
      participantsTotalScore.set(
        p.username,
        p.dailyTotalScore + (participantsTotalScore.get(p.username) ?? 0)
      );

      const generalScores: number[] = uniqueGradesWithoutActivityIdTypes.map(
        (type) => p.autoGradesWithoutActivityIdByTitle[type]?.score ?? 0
      );

      const managerScores: number[] = managerScoresHeadersForEachDay[
        day.id
        ].map((tellsyActivityName) =>
        p.autoGradesFromManagers
          .filter((a) => a.tellsyActivityName === tellsyActivityName)
          .reduce((sum, grade) => sum + grade.score ?? 0, 0)
      );

      const activityScores: number[] = filterActivitiesByDay(day.id)
        .map((a) => a.activityId)
        .map((activityId) => p.totalScoreByActivityId[activityId]);

      if (result[p.username]) {
        result[p.username] = [
          ...result[p.username],
          ...generalScores,
          ...managerScores,
          ...activityScores,
          p.dailyTotalScore,
        ];
      } else {
        result[p.username] = [
          ...generalScores,
          ...managerScores,
          ...activityScores,
          p.dailyTotalScore,
        ];
      }
    });
    return result;
  }, {} as Record<TellsyParticipantDto["username"], (string | number)[]>);

  const headerParticipantData = ["Логин", "Имя", "Команда", "Итоговый балл"];

  const upperHeader = [
    ...headerParticipantData.map(() => ""),

    ...line.days.reduce(
      (result, day) => [
        ...result,
        new Date(day.date).toLocaleDateString(),
        ...uniqueGradesWithoutActivityIdTypes.map(() => ""),
        ...managerScoresHeadersForEachDay[day.id].map(() => ``),
        ...filterActivitiesByDay(day.id).map(() => ""),
      ],
      new Array<string>()
    ),
  ];
  worksheet.addRow(upperHeader);

  const header = [
    ...headerParticipantData,
    ...line.days.reduce(
      (result, day) => [
        ...result,
        ...uniqueGradesWithoutActivityIdTypes.map(
          (type: GeneralAutoGradeTypeTitle) => generalAutoGradeTypesName[type]
        ),
        ...managerScoresHeadersForEachDay[day.id].map(
          (name) => `[${name}] (менеджерская)`
        ),
        ...filterActivitiesByDay(day.id).map((a) => a.name),
        "Итого за день",
      ],
      new Array<string>()
    ),
  ];

  //Add Header Row
  const headerRow = worksheet.addRow(header);

  // Cell Style : Fill and Border
  headerRow.eachCell((cell) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFCCFFE5" },
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  const rowsData: (string | number)[][] = participants.filter((participant) => !excludedParticipantsDto?.tellsyExcludedParticipantIds.includes(participant.participantId)).map((p) => [
    p.username,
    p.identifier,
    p.teamName ?? "",
    participantsTotalScore.get(p.username) ?? 0,
    ...participantsDailyGradesRow[p.username],
  ]);

  // Add participants grades row
  rowsData.forEach((rowData, _rowDataIndex) => {
    worksheet.addRow(rowData);
  });

  worksheet.getColumn(1).width = 20;
  worksheet.getColumn(2).width = 30;
  worksheet.getColumn(3).width = 10;
  worksheet.getColumn(4).width = 8;

  worksheet.autoFilter = {
    from: "A2",
    to: "D2",
  };

  //Footer Row
  worksheet.addRow([]);
  const footerRow = worksheet.addRow([
    "Отчёт сгенерирован в системе abbott-rating",
  ]);
  footerRow.getCell(1).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFCCFFE5" },
  };
  footerRow.getCell(1).border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };

  //Merge Cells
  worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);
}

function getActivitiesOfAutoGradesPerDay(days: DayDto[]): Record<DayDto["id"], string[]> {
  return days.reduce((accRecord, dayDto) => {
    const { id: dayId, autoGradeTypes } = dayDto;
    accRecord[dayId] = autoGradeTypes.map((autoGradeType) => autoGradeType.activityId);
    return accRecord;
  }, {} as Record<DayDto["id"], string[]>);
}

function addTeamsRatingWorksheet(
  workbook: Workbook,
  { line, teams, excludedParticipantsDto }: ExcelReportData,
  participantsViewModelForEachDay: Record<DayDto["id"],
    ParticipantWithGradesSummaryViewModel[]>
): void {
  const teamsTotalScore: Record<TellsyTeamDto["id"], number> = {};
  const calculatedTeams: TeamsWithParticipantsViewModel[] = [];
  const teamsViewModelForEachDay: Record<DayDto["id"],
    TeamsWithParticipantsViewModel[]> = line.days.reduce((result, day) => {
    const teamsViewModel: TeamsWithParticipantsViewModel[] = convertToTeamsWithParticipantsViewModel({
      day,
      teams,
      participants: participantsViewModelForEachDay[day.id],
      excludedParticipantsDto
    });

    calculatedTeams.push(...teamsViewModel)

    teamsViewModel.forEach((teamViewModel) => {
      if (teamsTotalScore[teamViewModel.id] > 0) {
        teamsTotalScore[teamViewModel.id] =
          teamViewModel.dailyTotalScore + teamsTotalScore[teamViewModel.id];
      } else {
        teamsTotalScore[teamViewModel.id] = teamViewModel.dailyTotalScore;
      }
    });

    return {
      ...result,
      [day.id]: teamsViewModel,
    };
  }, {} as Record<DayDto["id"], TeamsWithParticipantsViewModel[]>);

  const worksheet = workbook.addWorksheet("Рейтинг командный");

  const headerTotalData = ["Команда", "Итоговый балл", ""];
  const header = [...headerTotalData, ...line.days.map(() => "Балл за день")];

  const upperHeader = [
    ...headerTotalData.map(() => ""),
    ...line.days.reduce(
      (result, day) => [...result, new Date(day.date).toLocaleDateString()],
      new Array<string>()
    ),
  ];

  const teamIds = calculatedTeams?.map((calculatedTeam) => calculatedTeam.id);
  const rowsData: (number | string)[][] = teams?.filter((team) => teamIds.includes(team.id)).map((team, teamIndex) => [
    team.teamName,
    teamsTotalScore[team.id],
    "",
    ...line.days.map(
      (day) => teamsViewModelForEachDay[day.id][teamIndex].dailyTotalScore
    ),
  ]) ?? [];

  worksheet.addRow(upperHeader);
  worksheet.addRow(header);

  rowsData.forEach((rowData, rowDataIndex) => {
    worksheet.addRow(rowData);

    if (rowDataIndex > 2) {
      worksheet.getColumn(rowDataIndex + 1).width = 15;
    }
  });

  worksheet.getColumn(1).width = 30;
  worksheet.getColumn(2).width = 20;
}

function getParticipantsViewModelForEachDay(
  line: LineDto,
  manualGrades: GradesSummary[],
  participants: TellsyParticipantDto[],
  autoGrades: AutoGradeParticipantDto[],
  activities: TellsyActivityDto[],
  excludedParticipantsDto: ExcludedParticipantsDto
): Record<DayDto["id"], ParticipantWithGradesSummaryViewModel[]> {
  return line.days.reduce((result, day) => {
    const gradesSummaryEntities: Dictionary<GradesSummary> =
      manualGrades.reduce((acc, grade) => {
        if (grade.dayId === day.id) {
          return { ...acc, [grade.tellsyUserName]: grade };
        }

        return acc;
      }, {});
    return {
      ...result,
      [day.id]: convertToParticipantWithGradesSummaryViewModel({
        day,
        participants,
        gradesSummaryEntities,
        autoGrades,
        activities,
        excludedParticipantsDto
      }),
    };
  }, {} as Record<DayDto["id"], ParticipantWithGradesSummaryViewModel[]>);
}

export const ExcelReportUtils = {
  saveExcelReport,
};
