import { DayDto } from "@store/projects/model/dto/day.dto";
import { GradeDto, GradeTypeDto } from "@store/projects/model/dto/grade.dto";
import { LineDto } from "@store/projects/model/dto/line.dto";
import { TellsyActivityDto } from "@store/projects/model/dto/tellsy-activities.dto";
import { TellsyParticipantDto } from "@store/projects/model/dto/tellsy-participant.dto";

const HOST = "https://back.tellsy.ru";

const abbottApiScope = "abbott-rating-api";

const SCOPES_URL = {
  auth: "/api/auth_service",
  projects: `/${abbottApiScope}/projects`,
  grades: `/${abbottApiScope}/grades`,
  lines: `/${abbottApiScope}/lines`,
  tellsyEvents: "/api/event_service/abbott",
  tellsyActivities: "/api/event_service/abbott",
  tellsyParticipants: "/api/participant_service/abbott",
  tellsyTeams: "/api/participant_service/abbott",
};

const build = (scope: keyof typeof SCOPES_URL, path: string = ""): string =>
  HOST + SCOPES_URL[scope] + (path.startsWith("/") ? path : "/" + path);

export const API = {
  auth: {
    loadUser: (): string => build("auth", "user"),
    requestAuthToken: (): string => build("auth", "oauth/token"),
  },

  projects: {
    loadProjects: (): string => build("projects"),
    loadProject: (id: number): string => build("projects", `${id}`),
    addProject: (): string => build("projects"),
    updateProject: (id: number): string => build("projects", `${id}`),
    deleteProject: (id: number): string => build("projects", `${id}`),
  },

  lines: {
    updateExcludedParticipants: (id: number): string => build("lines", `${id}/excluded-participants`),
  },

  grades: {
    getGradeTypesByLineId: (): string => build("grades", "types"),
    createGradeType: (): string => build("grades", "type"),
    createGradeTypes: (lineId: LineDto["id"]): string =>
      build("grades", `types/line/${lineId}`),
    editGradeTypes: (lineId: LineDto["id"]): string =>
      build("grades", `/types/line/${lineId}`),
    deleteGradeType: (id: GradeTypeDto["id"]): string =>
      build("grades", `/type/${id}`),
    createGrade: (): string => build("grades"),
    editGrade: (id: GradeDto["id"]): string => build("grades", `${id}`),
    editMultipleGrades: (): string => build("grades", `multiple`),
    createGradeForMultipleParticipant: (): string =>
      build("grades", `multiple`),
    deleteGrade: (id: GradeDto["id"]): string => build("grades", `${id}`),
    getAllGradesByActivityAndUserName: (
      tellsyActivityId: TellsyActivityDto["activityId"],
      tellsyUsername: TellsyParticipantDto["username"]
    ): string =>
      build(
        "grades",
        `activity/${tellsyActivityId}/username/${tellsyUsername}`
      ),
    getGradesByActivityAndParticipantId: (
      tellsyActivityId: TellsyActivityDto["activityId"],
      tellsyParticipantId: TellsyParticipantDto["participantId"]
    ): string =>
      build(
        "grades",
        `activity/${tellsyActivityId}/participant/${tellsyParticipantId}`
      ),
    getGradesSummaryPerParticipant: (dayId: DayDto["id"]): string =>
      build("grades", `day/${dayId}`),
    getAutoGrades: (dayId: DayDto["id"]): string =>
      build("grades", `day/${dayId}/auto_grades`),
    editAutoGradeTypesByDayId: (dayId: DayDto["id"]): string =>
      build("grades", `day/${dayId}/auto_grade_types`),
    getAllowedAutoGradeTypes: (): string =>
      build("grades", "activity/allowed_auto_grade_types"),
    getLineReportData: (): string => build("grades", "line_report_data"),
  },

  tellsyEvents: {
    loadEvents: (): string => build("tellsyEvents", "events"),
  },

  tellsyActivities: {
    loadActivities: (): string => build("tellsyEvents", `event/activities`),
  },

  tellsyParticipants: {
    loadParticipants: (): string => build("tellsyParticipants", "participants"),
  },

  tellsyTeams: {
    loadTeams: (eventId: string): string =>
      build("tellsyTeams", `event/${eventId}/teams`),
  },
};
