import { Injectable, Signal } from "@angular/core";
import { Store } from "@ngrx/store";
import { TellsyEventsActions } from "@store/projects/actions";
import {
  LoadTellsyEventsPageableDto,
  TellsyEventDto,
  TellsyEventsPageableDto,
} from "@store/projects/model/dto/tellsy-event.dto";
import { TellsyEventsSelectors } from "@store/projects/selectors/tellsy-events.selectors";

@Injectable({
  providedIn: "root",
})
export class TellsyEventsFacade {
  constructor(private store: Store) {}
  loadTellsyEvents(pageable: LoadTellsyEventsPageableDto): void {
    this.store.dispatch(TellsyEventsActions.loadTellsyEvents({ pageable }));
  }

  // selectors

  selectAllTellsyEvents(): Signal<TellsyEventDto[]> {
    return this.store.selectSignal(TellsyEventsSelectors.selectAllTellsyEvents);
  }

  selectTellsyEvent(
    id: TellsyEventDto["id"]
  ): Signal<TellsyEventDto | undefined> {
    return this.store.selectSignal(TellsyEventsSelectors.selectTellsyEvent(id));
  }

  selectTellsyEvents(
    ids: TellsyEventDto["id"][]
  ): Signal<(TellsyEventDto | undefined)[]> {
    return this.store.selectSignal(
      TellsyEventsSelectors.selectTellsyEvents(ids)
    );
  }

  selectTellsyEventsPageableMetadata(): Signal<TellsyEventsPageableDto | null> {
    return this.store.selectSignal(
      TellsyEventsSelectors.selectTellsyEventsPageableMetadata
    );
  }
}
