import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "@shared/api.config";
import {
  CreateGradeDto,
  CreateGradeForMultipleParticipantDto,
  CreateGradeSuccessDto,
  CreateGradeTypeDto,
  CreateGradeTypeSuccessDto,
  CreateGradeTypesDto,
  CreateGradeTypesSuccessDto,
  DeleteGradeDto,
  DeleteGradeSuccessDto,
  DeleteGradeTypeDto,
  DeleteGradeTypeSuccessDto,
  EditAutoGradeTypesDto,
  EditAutoGradeTypesSuccessDto,
  EditGradeDto,
  EditGradeSuccessDto,
  EditGradeTypesDto,
  EditGradeTypesSuccessDto,
  EditMultipleGradesDto,
  EditMultipleGradesSuccessDto,
  GetAllGradesByActivityAndUserNameDto,
  GetAllGradesByActivityAndUserNameSuccessDto,
  GetAllowedAutoGradeTypesSuccessDto,
  GetAutoGradesDto,
  GetAutoGradesSuccessDto,
  GetGradeTypesByLineIdDto,
  GetGradeTypesByLineIdSuccessDto,
  GetGradesByActivityAndParticipantIdDto,
  GetGradesByActivityAndParticipantIdSuccessDto,
  GetGradesSummaryPerParticipantDto,
  GetGradesSummaryPerParticipantSuccessDto,
  GetLineReportDataSuccessDto,
  GradeTypeDto,
} from "@store/projects/model/dto/grade.dto";
import { LineDto } from "@store/projects/model/dto/line.dto";
import { Observable, map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GradesService {
  constructor(private http: HttpClient) {}

  loadGradeTypesByLineId(
    dto: GetGradeTypesByLineIdDto
  ): Observable<GetGradeTypesByLineIdSuccessDto> {
    const url = API.grades.getGradeTypesByLineId();

    const params = {
      lineId: dto.lineId,
    };

    return this.http.get<GetGradeTypesByLineIdSuccessDto>(url, {
      params,
    });
  }

  createGradeType(
    dto: CreateGradeTypeDto
  ): Observable<CreateGradeTypeSuccessDto> {
    const url = API.grades.createGradeType();

    const params = {
      lineId: dto.lineId,
    };

    return this.http.post<CreateGradeTypeSuccessDto>(url, dto, {
      params,
    });
  }

  createGradeTypes(
    dto: CreateGradeTypesDto
  ): Observable<CreateGradeTypesSuccessDto> {
    const url = API.grades.createGradeTypes(dto.lineId);

    return this.http.post<CreateGradeTypesSuccessDto>(url, dto.gradeTypeDtos);
  }

  editGradeTypes(dto: EditGradeTypesDto): Observable<EditGradeTypesSuccessDto> {
    const url = API.grades.editGradeTypes(dto.lineId);
    return this.http
      .put<GradeTypeDto[]>(url, dto.gradeTypeDtos)
      .pipe(
        map((response) => ({ lineId: dto.lineId, gradeTypeDtos: response }))
      );
  }

  deleteGradeType(
    dto: DeleteGradeTypeDto
  ): Observable<DeleteGradeTypeSuccessDto> {
    const url = API.grades.deleteGradeType(dto);
    return this.http.delete<DeleteGradeTypeSuccessDto>(url);
  }

  loadGradesSummaryForDay(
    dto: GetGradesSummaryPerParticipantDto
  ): Observable<GetGradesSummaryPerParticipantSuccessDto> {
    const url = API.grades.getGradesSummaryPerParticipant(dto);
    return this.http.get<GetGradesSummaryPerParticipantSuccessDto>(url);
  }

  createGrade(dto: CreateGradeDto): Observable<CreateGradeSuccessDto> {
    const url = API.grades.createGrade();
    return this.http.post<CreateGradeSuccessDto>(url, dto);
  }

  editGrade(dto: EditGradeDto): Observable<EditGradeSuccessDto> {
    const url = API.grades.editGrade(dto.id);
    return this.http.put<EditGradeSuccessDto>(url, dto);
  }

  editMultipleGrades(
    dto: EditMultipleGradesDto
  ): Observable<EditMultipleGradesSuccessDto> {
    const url = API.grades.editMultipleGrades();
    return this.http.put<EditMultipleGradesSuccessDto>(url, dto);
  }

  createGradeForMultipleParticipant(
    dto: CreateGradeForMultipleParticipantDto
  ): Observable<EditMultipleGradesSuccessDto> {
    const url = API.grades.editMultipleGrades();
    return this.http.post<EditMultipleGradesSuccessDto>(url, dto);
  }

  deleteGrade(dto: DeleteGradeDto): Observable<DeleteGradeSuccessDto> {
    const url = API.grades.deleteGrade(dto);
    return this.http.delete<DeleteGradeSuccessDto>(url);
  }

  loadAllGradesByActivityAndUserName(
    dto: GetAllGradesByActivityAndUserNameDto
  ): Observable<GetAllGradesByActivityAndUserNameSuccessDto> {
    const url = API.grades.getAllGradesByActivityAndUserName(
      dto.tellsyActivityId,
      dto.tellsyUsername
    );

    return this.http.get<GetAllGradesByActivityAndUserNameSuccessDto>(url);
  }

  loadGradesByActivityAndParticipantId(
    dto: GetGradesByActivityAndParticipantIdDto
  ): Observable<GetGradesByActivityAndParticipantIdSuccessDto> {
    const url = API.grades.getGradesByActivityAndParticipantId(
      dto.tellsyActivityId,
      dto.tellsyParticipantId
    );

    return this.http.get<GetGradesByActivityAndParticipantIdSuccessDto>(url);
  }

  loadAutoGrades(dto: GetAutoGradesDto): Observable<GetAutoGradesSuccessDto> {
    const url = API.grades.getAutoGrades(dto);
    return this.http.get<GetAutoGradesSuccessDto>(url);
  }

  editAutoGradeTypesByDayId(
    dto: EditAutoGradeTypesDto
  ): Observable<EditAutoGradeTypesSuccessDto> {
    const url = API.grades.editAutoGradeTypesByDayId(dto.dayId);
    return this.http.put<EditAutoGradeTypesSuccessDto>(url, dto.autoGradeTypes);
  }

  getAllowedAutoGradeTypes(): Observable<GetAllowedAutoGradeTypesSuccessDto> {
    const url = API.grades.getAllowedAutoGradeTypes();
    return this.http.get<GetAllowedAutoGradeTypesSuccessDto>(url);
  }

  getLineReportData(
    lineId: LineDto["id"]
  ): Observable<GetLineReportDataSuccessDto> {
    const url = API.grades.getLineReportData();
    const params = { lineId };
    return this.http.get<GetLineReportDataSuccessDto>(url, { params });
  }
}
