import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TellsyEventsActions } from "@store/projects/actions";
import { TellsyEventsService } from "@store/projects/services";
import { map, switchMap } from "rxjs";

@Injectable()
export class TellsyEventsEffects {
  loadTellsyEvents = createEffect(() => {
    return this.actions.pipe(
      ofType(TellsyEventsActions.loadTellsyEvents),
      switchMap(({ pageable }) =>
        this.service
          .loadTellsyEvents(pageable)
          .pipe(map((dto) => ({ pageable, dto })))
      ),
      map(({ pageable, dto }) =>
        TellsyEventsActions.loadTellsyEventsSuccess({ pageable, dto })
      )
    );
  });

  constructor(private actions: Actions, private service: TellsyEventsService) {}
}
