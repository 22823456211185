import { Injectable, Signal } from "@angular/core";
import { Store } from "@ngrx/store";
import { TellsyParticipantsActions } from "@store/projects/actions/";
import { LoadParticipantsDto, TellsyParticipantDto, } from "@store/projects/model/dto/tellsy-participant.dto";
import {
  ParticipantWithGradesSummaryViewModel
} from "@store/projects/model/tellsy-participant-with-grades-summary.view-model";
import { ProjectsCombinedSelectors } from "@store/projects/selectors/combined.selectors";
import { TellsyParticipantsSelectors } from "@store/projects/selectors/tellsy-participants.selectors";
import { ExcludedParticipantsDto } from "@store/projects/model/dto/line.dto";

@Injectable({
  providedIn: "root",
})
export class TellsyParticipantsFacade {
  constructor(private store: Store) {
  }

  loadParticipants(dto: LoadParticipantsDto): void {
    this.store.dispatch(TellsyParticipantsActions.loadTellsyParticipants(dto));
  }

  updateExcludedTellsyParticipants(dto: ExcludedParticipantsDto): void {
    this.store.dispatch(TellsyParticipantsActions.updateExcludedTellsyParticipants(dto));
  }

  // selectors

  selectIsLoading(): Signal<boolean> {
    return this.store.selectSignal(TellsyParticipantsSelectors.selectIsLoading);
  }

  selectParticipants(): Signal<TellsyParticipantDto[]> {
    return this.store.selectSignal(
      TellsyParticipantsSelectors.selectAllTellsyParticipants
    );
  }

  selectAllParticipantsWithGradesSummaryViewModel(): Signal<ParticipantWithGradesSummaryViewModel[]> {
    return this.store.selectSignal(
      ProjectsCombinedSelectors.selectAllParticipantsWithGradesSummaryViewModel
    );
  }

  selectExcludedParticipants(): Signal<ExcludedParticipantsDto> {
    return this.store.selectSignal(ProjectsCombinedSelectors.selectExcludedParticipants);
  }
}
