import { createSelector } from "@ngrx/store";
import { convertToTeamsWithParticipantsViewModel } from "@store/projects/model";
import { DayDto } from "@store/projects/model/dto/day.dto";
import { ProjectDto } from "@store/projects/model/dto/projects.dto";
import { TellsyActivityDto } from "@store/projects/model/dto/tellsy-activities.dto";
import {
  convertToParticipantWithGradesSummaryViewModel
} from "@store/projects/model/tellsy-participant-with-grades-summary.view-model";
import { GradesSelectors } from "@store/projects/selectors/grades.selectors";
import { LinesSelectors } from "@store/projects/selectors/lines.selectors";
import { ProjectsSelectors } from "@store/projects/selectors/projects.selectors";
import { RouterSelectors } from "@store/projects/selectors/router.selectors";
import { TellsyActivitiesSelectors } from "@store/projects/selectors/tellsy-activities.selectors";
import { TellsyParticipantsSelectors } from "@store/projects/selectors/tellsy-participants.selectors";
import { TellsyTeamsSelectors } from "@store/projects/selectors/tellsy-teams.selectors";
import { ExcludedParticipantsDto } from "@store/projects/model/dto/line.dto";

const selectCurrentProject = createSelector(
  RouterSelectors.selectRouteNestedParams,
  ProjectsSelectors.selectAllProjectsEntities,
  (params, projectsEntities) => projectsEntities[params.projectId]
);

const selectCurrentLine = createSelector(
  RouterSelectors.selectRouteNestedParams,
  LinesSelectors.selectLinesEntities,
  (params, linesEntities) => linesEntities[params.lineId]
);

const selectCurrentLineDays = createSelector(
  selectCurrentLine,
  (line) => line?.days
);

const selectCurrentLineDaysEntities = createSelector(
  selectCurrentLineDays,
  (days) =>
    days?.reduce(
      (acc, day) => ({ ...acc, [day.id]: day }),
      {} as Record<DayDto["id"], DayDto>
    )
);

const selectCurrentDay = createSelector(
  RouterSelectors.selectRouteNestedParams,
  selectCurrentLineDaysEntities,
  (params, daysEntities) => daysEntities?.[params.dayId]
);

const selectAllProjectsViewModel = createSelector(
  ProjectsSelectors.selectAllProjects,
  LinesSelectors.selectLinesEntities,
  (projects, linesEntities) =>
    projects?.map(
      (project): ProjectDto => ({
        ...project,
        lines: project.lines.map(({ id }) => linesEntities[id]!),
      })
    )
);

const selectExcludedParticipants = createSelector(
  selectCurrentLine,
  (lineDto) => ({
      lineId: lineDto?.id,
      tellsyExcludedParticipantIds: lineDto?.tellsyExcludedParticipantIds
    } as ExcludedParticipantsDto
  )
);

const selectAllParticipantsWithGradesSummaryViewModel = createSelector(
  selectCurrentDay,
  TellsyParticipantsSelectors.selectAllTellsyParticipants,
  GradesSelectors.selectGradesSummaryEntities,
  GradesSelectors.selectAllAutoGrades,
  TellsyActivitiesSelectors.selectAllTellsyActivities,
  selectExcludedParticipants,
  (day, participants, gradesSummaryEntities, autoGrades, activities, excludedParticipantsDto) =>
    day
      ? convertToParticipantWithGradesSummaryViewModel({
        day,
        participants,
        gradesSummaryEntities,
        autoGrades,
        activities,
        excludedParticipantsDto
      })
      : []
);

const selectTeamsWithParticipantsViewModelForCurrentDay = createSelector(
  selectCurrentDay,
  TellsyTeamsSelectors.selectAllTellsyTeams,
  selectAllParticipantsWithGradesSummaryViewModel,
  selectExcludedParticipants,
  (day, teams, participants, excludedParticipantsDto) =>
    day && participants
      ? convertToTeamsWithParticipantsViewModel({ day, teams, participants, excludedParticipantsDto})
      : []
);

const selectIsGradeTableLoading = createSelector(
  GradesSelectors.selectIsGradesSummaryLoading,
  TellsyParticipantsSelectors.selectIsLoading,
  GradesSelectors.selectIsAutoGradesLoading,
  (isGradesSummaryLoading, isParticipantsLoading, isAutoGradesLoading) =>
    isGradesSummaryLoading || isParticipantsLoading || isAutoGradesLoading
);

const selectAllNotExcludedActivitiesForCurrentLine = createSelector(
  selectCurrentLine,
  TellsyActivitiesSelectors.selectAllTellsyActivities,
  LinesSelectors.selectLinesEntities,
  (currentLine, activities, linesEntities) => {
    if (!currentLine) {
      return activities;
    }

    const excludedIds = new Set(
      linesEntities[currentLine.id]?.tellsyExcludedActivitiesIds ?? []
    );

    if (!excludedIds.size) {
      return activities;
    }

    return activities.filter(
      (activity) => !excludedIds.has(activity.activityId)
    );
  }
);

const selectAllNotExcludedActivitiesEntitiesForCurrentLine = createSelector(
  selectCurrentLine,
  TellsyActivitiesSelectors.selectAllTellsyActivitiesEntities,
  LinesSelectors.selectLinesEntities,
  (currentLine, activitiesEntities, linesEntities) => {
    if (!currentLine) {
      return activitiesEntities;
    }

    const excludedIds = new Set(
      linesEntities[currentLine.id]?.tellsyExcludedActivitiesIds ?? []
    );

    if (!excludedIds.size) {
      return activitiesEntities;
    }

    const result: TellsyActivityDto[] = [];
    for (const activityId in activitiesEntities) {
      if (!excludedIds.has(activityId)) {
        const activity = activitiesEntities[activityId]
        if (activity) {
          result.push(activity);
        }
      }
    }

    return activitiesEntities;
  }
);

const selectIsReportLoading = createSelector(
  TellsyActivitiesSelectors.selectIsActivitiesLoading,
  TellsyTeamsSelectors.selectIsTeamsLoading,
  ProjectsSelectors.selectIsLoadingProjectsPending,
  GradesSelectors.selectIsReportLoading,
  (
    isActivitiesLoading,
    isTeamsLoading,
    isLoadingProjectsPending,
    isReportLoading
  ) =>
    isActivitiesLoading ||
    isTeamsLoading ||
    isLoadingProjectsPending ||
    isReportLoading
);

export const ProjectsCombinedSelectors = {
  selectAllProjectsViewModel,
  selectAllParticipantsWithGradesSummaryViewModel,
  selectTeamsWithParticipantsViewModelForCurrentDay,
  selectIsGradeTableLoading,
  selectAllNotExcludedActivitiesForCurrentLine,
  selectAllNotExcludedActivitiesEntitiesForCurrentLine,
  selectCurrentProject,
  selectCurrentLine,
  selectCurrentLineDays,
  selectCurrentLineDaysEntities,
  selectCurrentDay,
  selectIsReportLoading,
  selectExcludedParticipants
};
