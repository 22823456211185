import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "@shared/api.config";
import {
  LoadTellsyTeamsDto,
  LoadTellsyTeamsSuccessDto,
} from "@store/projects/model/dto/team.dto";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TellsyTeamsService {
  constructor(private http: HttpClient) {}

  loadTellsyTeams(
    dto: LoadTellsyTeamsDto
  ): Observable<LoadTellsyTeamsSuccessDto> {
    const url = API.tellsyTeams.loadTeams(dto.eventId);

    return this.http.get<LoadTellsyTeamsSuccessDto>(url);
  }
}
