import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TellsyTeamsActions } from "@store/projects/actions";
import { TellsyTeamsService } from "@store/projects/services";
import { map, switchMap } from "rxjs";

@Injectable()
export class TellsyTeamsEffects {
  loadTellsyTeams = createEffect(() => {
    return this.actions.pipe(
      ofType(TellsyTeamsActions.loadTellsyTeams),
      switchMap(({ dto }) => this.service.loadTellsyTeams(dto)),
      map((dto) => TellsyTeamsActions.loadTellsyTeamsSuccess({ dto }))
    );
  });

  constructor(private actions: Actions, private service: TellsyTeamsService) {}
}
