import { createActionGroup, props } from "@ngrx/store";
import {
  LoadTellsyEventsPageableDto,
  LoadTellsyEventsSuccessDto,
} from "@store/projects/model/dto/tellsy-event.dto";

export const TellsyEventsActions = createActionGroup({
  source: "Projects/Tellsy Events",
  events: {
    "Load Tellsy Events": props<{ pageable: LoadTellsyEventsPageableDto }>(),
    "Load Tellsy Events Success": props<{
      pageable: LoadTellsyEventsPageableDto;
      dto: LoadTellsyEventsSuccessDto;
    }>(),
  },
});
