import { createSelector } from "@ngrx/store";
import { DayDto } from "@store/projects/model/dto/day.dto";
import { LineDto } from "@store/projects/model/dto/line.dto";
import { selectProjectsState } from "@store/projects/projects.module";
import { linesSelectors } from "@store/projects/reducers/lines.reducer";

const selectLinesState = createSelector(
  selectProjectsState,
  (state) => state.lines
);

const selectAllLines = createSelector(
  selectLinesState,
  linesSelectors.selectAll
);

const selectLinesEntities = createSelector(
  selectLinesState,
  linesSelectors.selectEntities
);

const selectLine = (id: LineDto["id"]) =>
  createSelector(selectLinesEntities, (linesEntities) => linesEntities[id]);

const selectDay = (lineId: LineDto["id"], dayId: DayDto["id"]) =>
  createSelector(selectLinesEntities, (linesEntities) => {
    return linesEntities[lineId]?.days.find((day) => day.id === dayId);
  });

const selectLineDays = (id: LineDto["id"]) =>
  createSelector(selectLine(id), (line) => line?.days);

export const LinesSelectors = {
  selectAllLines,
  selectLinesEntities,
  selectLine,
  selectDay,
  selectLineDays
};
