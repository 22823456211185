import { createSelector } from "@ngrx/store";
import { TellsyEventDto } from "@store/projects/model/dto/tellsy-event.dto";
import { selectProjectsState } from "@store/projects/projects.module";
import { tellsyEventsSelectors } from "@store/projects/reducers/tellsy-events.reducer";

const selectTellsyEventsState = createSelector(
  selectProjectsState,
  (state) => state.tellsyEvents
);

const selectAllTellsyEvents = createSelector(
  selectTellsyEventsState,
  tellsyEventsSelectors.selectAll
);

const selectAllTellsyEventsEntities = createSelector(
  selectTellsyEventsState,
  tellsyEventsSelectors.selectEntities
);

const selectTellsyEvent = (id: TellsyEventDto["id"]) =>
  createSelector(selectAllTellsyEventsEntities, (entities) => entities[id]);

const selectTellsyEvents = (ids: TellsyEventDto["id"][]) =>
  createSelector(selectAllTellsyEventsEntities, (entities) =>
    ids.map((id) => entities[id])
  );

const selectTellsyEventsPageableMetadata = createSelector(
  selectTellsyEventsState,
  (state) => state?.pageableMetadata
);

const selectIsLoadingTellsyEventsPending = createSelector(
  selectTellsyEventsState,
  (state) => state?.loadingTellsyEventsPending
);

export const TellsyEventsSelectors = {
  selectAllTellsyEvents,
  selectTellsyEvent,
  selectTellsyEvents,
  selectTellsyEventsPageableMetadata,
  selectIsLoadingTellsyEventsPending,
};
