import { Injectable, Signal } from "@angular/core";
import { Store } from "@ngrx/store";
import { GradesActions } from "@store/projects/actions";
import {
  AllowedAutoGradeTypesDto,
  CreateGradeDto,
  CreateGradeForMultipleParticipantDto,
  CreateGradeTypeDto,
  CreateGradeTypesDto,
  DeleteGradeDto,
  DeleteGradeTypeDto,
  EditAutoGradeTypesDto,
  EditGradeDto,
  EditGradeTypesDto,
  EditMultipleGradesDto,
  GetAllGradesByActivityAndUserNameDto,
  GetAutoGradesDto,
  GetGradeTypesByLineIdDto,
  GetGradesByActivityAndParticipantIdDto,
  GetGradesSummaryPerParticipantDto,
  GradeDto,
  GradeTypeDto,
} from "@store/projects/model/dto/grade.dto";
import { TellsyParticipantDto } from "@store/projects/model/dto/tellsy-participant.dto";
import { GradesSelectors } from "@store/projects/selectors/grades.selectors";

import { Dictionary } from "@ngrx/entity";
import { LineDto } from "@store/projects/model/dto/line.dto";
import { ProjectDto } from "@store/projects/model/dto/projects.dto";
import { TellsyTeamDto } from "@store/projects/model/dto/team.dto";
import { TellsyActivityDto } from "@store/projects/model/dto/tellsy-activities.dto";
import { ProjectsCombinedSelectors } from "@store/projects/selectors/combined.selectors";

@Injectable({
  providedIn: "root",
})
export class GradesFacade {
  constructor(private store: Store) {}
  loadGradeTypesByLineId(dto: GetGradeTypesByLineIdDto): void {
    this.store.dispatch(GradesActions.loadGradeTypesByLineId({ dto }));
  }

  createGradeType(dto: CreateGradeTypeDto): void {
    this.store.dispatch(GradesActions.createGradeType({ dto }));
  }

  createGradeTypes(dto: CreateGradeTypesDto): void {
    this.store.dispatch(GradesActions.createGradeTypes({ dto }));
  }

  editGradeTypes(dto: EditGradeTypesDto): void {
    this.store.dispatch(GradesActions.editGradeTypes({ dto }));
  }

  deleteGradeType(dto: DeleteGradeTypeDto): void {
    this.store.dispatch(GradesActions.deleteGradeType({ dto }));
  }

  loadGradesSummaryForDay(dto: GetGradesSummaryPerParticipantDto): void {
    this.store.dispatch(GradesActions.loadGradesSummaryForDay({ dto }));
  }

  loadAutoGrades(dto: GetAutoGradesDto): void {
    this.store.dispatch(GradesActions.loadAutoGrades({ dto }));
  }

  editAutoGradeTypes(dto: EditAutoGradeTypesDto): void {
    this.store.dispatch(GradesActions.editAutoGradeTypes({ dto }));
  }

  createGrade(dto: CreateGradeDto): void {
    this.store.dispatch(GradesActions.createGrade({ dto }));
  }

  editGrade(dto: EditGradeDto): void {
    this.store.dispatch(GradesActions.editGrade({ dto }));
  }

  editMultipleGrades(dto: EditMultipleGradesDto): void {
    this.store.dispatch(GradesActions.editMultipleGrades({ dto }));
  }

  createGradeForMultipleParticipant(
    dto: CreateGradeForMultipleParticipantDto
  ): void {
    this.store.dispatch(
      GradesActions.createGradeForMultipleParticipant({ dto })
    );
  }

  deleteGrade(dto: DeleteGradeDto): void {
    this.store.dispatch(GradesActions.deleteGrade({ dto }));
  }

  loadAllGradesByActivityAndUserName(
    dto: GetAllGradesByActivityAndUserNameDto
  ): void {
    this.store.dispatch(
      GradesActions.loadAllGradesByActivityAndUserName({ dto })
    );
  }

  loadGradesSumByActivityAndParticipantId(
    dto: GetGradesByActivityAndParticipantIdDto
  ): void {
    this.store.dispatch(
      GradesActions.loadGradesByActivityAndParticipantId({ dto })
    );
  }

  loadAllowedAutoGradeTypes(): void {
    this.store.dispatch(GradesActions.loadAllowedAutoGradeTypes());
  }

  saveExcelReport(data: {
    lineId: LineDto["id"];
    activities: TellsyActivityDto[];
    projectTitle: ProjectDto["title"];
    teams: TellsyTeamDto[];
  }): void {
    this.store.dispatch(GradesActions.loadProjectExcelReport(data));
  }

  // selectors

  selectAllGradesTypes(): Signal<GradeTypeDto[]> {
    return this.store.selectSignal(GradesSelectors.selectAllGradesTypes);
  }

  selectIsGradeTableLoading(): Signal<boolean> {
    return this.store.selectSignal(
      ProjectsCombinedSelectors.selectIsGradeTableLoading
    );
  }

  selectAllGrades(): Signal<GradeDto[]> {
    return this.store.selectSignal(GradesSelectors.selectAllGrades);
  }

  selectIsGradesSummaryLoading(): Signal<boolean> {
    return this.store.selectSignal(
      GradesSelectors.selectIsGradesSummaryLoading
    );
  }

  selectGradesByUserNameAndActivityId(
    username: TellsyParticipantDto["username"],
    activityId: TellsyActivityDto["activityId"]
  ): Signal<GradeDto[]> {
    return this.store.selectSignal(
      GradesSelectors.selectGradesByUserNameAndActivityId(username, activityId)
    );
  }

  selectAllAllowedAutoGradesTypes(): Signal<AllowedAutoGradeTypesDto[]> {
    return this.store.selectSignal(
      GradesSelectors.selectAllAllowedAutoGradesTypes
    );
  }

  selectAllAllowedAutoGradesTypesEntities(): Signal<
    Dictionary<AllowedAutoGradeTypesDto>
  > {
    return this.store.selectSignal(
      GradesSelectors.selectAllAllowedAutoGradesTypesEntities
    );
  }

  selectIsReportLoading(): Signal<boolean> {
    return this.store.selectSignal(
      ProjectsCombinedSelectors.selectIsReportLoading
    );
  }
}
