import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { TellsyEventsActions } from "@store/projects/actions/";
import { TellsyEventDto } from "@store/projects/model/dto/tellsy-event.dto";
import { TellsyEventsState } from "@store/projects/reducers";

export const tellsyEventsAdapter: EntityAdapter<TellsyEventDto> =
  createEntityAdapter<TellsyEventDto>();

export const initialState: TellsyEventsState =
  tellsyEventsAdapter.getInitialState({
    pageableMetadata: null,
    loadingTellsyEventsPending: false,
  });

export const tellsyEventsReducer = createReducer<TellsyEventsState>(
  initialState,

  on(
    TellsyEventsActions.loadTellsyEvents,
    (state): TellsyEventsState => ({
      ...state,
      loadingTellsyEventsPending: true,
    })
  ),

  on(
    TellsyEventsActions.loadTellsyEventsSuccess,
    (state, { pageable, dto }): TellsyEventsState => {
      let updateMethod = tellsyEventsAdapter.setAll;
      if (
        pageable.search !== state?.pageableMetadata?.search &&
        pageable.page === 0
      ) {
        updateMethod = tellsyEventsAdapter.setAll;
      } else {
        updateMethod = tellsyEventsAdapter.upsertMany;
      }

      // show selected events first, if search query was not specified
      const events = pageable.search
        ? [...dto.pages.content, ...dto.customEvents]
        : [...dto.customEvents, ...dto.pages.content];

      return updateMethod(events, {
        ...state,
        pageableMetadata: {
          page: dto.pages.number,
          last: dto.pages.last,
          search: pageable.search,
        },
        loadingTellsyEventsPending: false,
      });
    }
  )
);

export const tellsyEventsSelectors = tellsyEventsAdapter.getSelectors(
  (state: TellsyEventsState) => state
);
