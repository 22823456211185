import { Dictionary } from "@ngrx/entity";
import { DayDto } from "@store/projects/model/dto/day.dto";
import {
  ActivityScore,
  AutoGradeParticipantDto,
  AutoGradeTypeTitle,
  GradesSummary,
  ScoreItem,
} from "@store/projects/model/dto/grade.dto";
import { TellsyActivityDto } from "@store/projects/model/dto/tellsy-activities.dto";
import { TellsyParticipantDto } from "@store/projects/model/dto/tellsy-participant.dto";
import { ExcludedParticipantsDto } from "@store/projects/model/dto/line.dto";

export interface ParticipantWithGradesSummaryViewModel
  extends TellsyParticipantDto {
  gradesSummary: GradesSummary;
  activityScoresByActivityId: Record<
    ActivityScore["tellsyActivityId"],
    ActivityScore["activityScore"]
  >;
  autoGrades: AutoGradeParticipantDto | undefined;
  autoGradesByActivityId: Record<string, ScoreItem[]>;
  autoGradesFromManagers: ScoreItem[];
  autoGradesWithoutActivityId: ScoreItem[];
  autoGradesWithoutActivityIdByTitle: Record<AutoGradeTypeTitle, ScoreItem>;
  dailyTotalManualScore: number;
  dailyTotalAutoScore: number;
  dailyTotalAutoScoreWithSetActivityId: number;
  dailyTotalScore: number;
  totalScoreByActivityId: Record<string, number>;
}

export const convertToParticipantWithGradesSummaryViewModel = ({
  day,
  participants,
  gradesSummaryEntities,
  autoGrades,
  activities,
  excludedParticipantsDto
}: {
  day: DayDto;
  participants: TellsyParticipantDto[];
  gradesSummaryEntities: Dictionary<GradesSummary>;
  autoGrades: AutoGradeParticipantDto[];
  activities: TellsyActivityDto[];
  excludedParticipantsDto: ExcludedParticipantsDto;
}): ParticipantWithGradesSummaryViewModel[] =>
  participants.filter((participant) => {
    if (!excludedParticipantsDto?.tellsyExcludedParticipantIds) {
      return true;
    }
    return !excludedParticipantsDto?.tellsyExcludedParticipantIds.includes(participant.participantId);
  }).map((participant) => {
    const autoGradesFromManagers: ScoreItem[] = [];
    const autoGradesWithoutActivityId: ScoreItem[] = [];
    const autoGradesWithoutActivityIdByTitle: Record<
      AutoGradeTypeTitle,
      ScoreItem
    > = {} as Record<AutoGradeTypeTitle, ScoreItem>;

    const autoGradesSummaryEntities: Dictionary<AutoGradeParticipantDto> =
      autoGrades.reduce((acc, grade) => {
        if (grade.dayId === day.id) {
          return { ...acc, [grade.tellsyUserName]: grade };
        }
        return acc;
      }, {} as Dictionary<AutoGradeParticipantDto>);

    const totalScore =
      gradesSummaryEntities[participant.username]?.dailyScore ?? 0;
    let totalAutoScore = 0;
    let dailyTotalAutoScoreWithSetActivityId = 0;
    const autoGradesByActivityId: Record<string, ScoreItem[]> =
      autoGradesSummaryEntities[participant.username]
        ? autoGradesSummaryEntities[
            participant.username
          ]!.autoScoreBreakdown.reduce((result, autoScoreEntry) => {
            totalAutoScore += autoScoreEntry.score;

            if (autoScoreEntry.autoGradeTypeTitle === "MANAGEMENT_ASSESSMENT") {
              autoGradesFromManagers.push(autoScoreEntry);
              return result;
            }

            if (!autoScoreEntry.tellsyActivityId) {
              autoGradesWithoutActivityId.push(autoScoreEntry);
              autoGradesWithoutActivityIdByTitle[
                autoScoreEntry.autoGradeTypeTitle
              ] = autoScoreEntry;
              return result;
            }

            dailyTotalAutoScoreWithSetActivityId += autoScoreEntry.score;

            return {
              ...result,
              [autoScoreEntry.tellsyActivityId]: result[
                autoScoreEntry.tellsyActivityId
              ]
                ? [...result[autoScoreEntry.tellsyActivityId], autoScoreEntry]
                : [autoScoreEntry],
            };
          }, {} as Record<string, ScoreItem[]>)
        : {};

    const autoGradesSumByActivityId: Record<string, number> = Object.keys(
      autoGradesByActivityId
    ).reduce(
      (result, activityId) => ({
        ...result,
        [activityId]: autoGradesByActivityId[activityId].reduce(
          (sum, scoreItem) => sum + scoreItem.score,
          0
        ),
      }),
      {}
    );

    const activityScoresByActivityId = gradesSummaryEntities[
      participant.username
    ]
      ? gradesSummaryEntities[participant.username]!.activityScores.reduce(
          (result, activityScore) => ({
            ...result,
            [activityScore.tellsyActivityId]: activityScore.activityScore,
          }),
          {} as Record<
            ActivityScore["tellsyActivityId"],
            ActivityScore["activityScore"]
          >
        )
      : {};

    const totalScoreByActivityId: Record<string, number> = activities.reduce(
      (accumulator, { activityId }) => {
        return {
          ...accumulator,
          [activityId]:
            (autoGradesSumByActivityId[activityId] ?? 0) +
            (activityScoresByActivityId[activityId] ?? 0),
        };
      },
      {}
    );

    return {
      ...participant,
      gradesSummary: gradesSummaryEntities[participant.username],
      activityScoresByActivityId,
      autoGrades: autoGradesSummaryEntities[participant.username],
      autoGradesByActivityId,
      autoGradesWithoutActivityId,
      autoGradesFromManagers,
      autoGradesWithoutActivityIdByTitle,
      dailyTotalManualScore: totalScore,
      dailyTotalAutoScore: totalAutoScore,
      dailyTotalAutoScoreWithSetActivityId,
      dailyTotalScore: totalScore + totalAutoScore,
      totalScoreByActivityId,
    } as ParticipantWithGradesSummaryViewModel;
  });
