import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AUTH_STATE_ID, AuthState } from "@store/auth/reducers";

const selectAuthState = createFeatureSelector<AuthState>(AUTH_STATE_ID);

const selectUser = createSelector(selectAuthState, (state) => state?.user);

const selectError = createSelector(selectAuthState, (state) => state?.error);

const selectPending = createSelector(
  selectAuthState,
  (state) => state?.pending
);

export const AuthSelectors = {
  selectUser,
  selectError,
  selectPending,
};
