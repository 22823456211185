import { Params } from "@angular/router";
import { getRouterSelectors, RouterReducerState } from "@ngrx/router-store";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DayDto } from "@store/projects/model/dto/day.dto";
import { LineDto } from "@store/projects/model/dto/line.dto";
import { ProjectDto } from "@store/projects/model/dto/projects.dto";

export const selectRouter = createFeatureSelector<RouterReducerState>("router");

const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectRouteDataParam, // factory function to select a route data param
  selectUrl, // select the current url
  selectTitle, // select the title if available
} = getRouterSelectors();

const selectRouteNestedParams = createSelector(selectRouter, (router) => {
  let currentRoute = router?.state?.root;
  let params: Params = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params,
    };
  }
  return params as {
    projectId: ProjectDto["id"];
    lineId: LineDto["id"];
    dayId: DayDto["id"];
  };
});

export const RouterSelectors = {
  selectCurrentRoute,
  selectFragment,
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectRouteDataParam,
  selectUrl,
  selectTitle,
  selectRouteNestedParams,
};
