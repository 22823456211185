import { createActionGroup, props } from "@ngrx/store";
import {
  LoadTellsyTeamsDto,
  LoadTellsyTeamsSuccessDto,
} from "@store/projects/model/dto/team.dto";

export const TellsyTeamsActions = createActionGroup({
  source: "Projects/Tellsy Teams",
  events: {
    "Load Tellsy Teams": props<{ dto: LoadTellsyTeamsDto }>(),
    "Load Tellsy Teams Success": props<{
      dto: LoadTellsyTeamsSuccessDto;
    }>(),
  },
});
