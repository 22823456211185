import { createActionGroup, props } from "@ngrx/store";
import {
  LoadTellsyActivitiesDto,
  LoadTellsyActivitiesSuccessDto,
} from "@store/projects/model/dto/tellsy-activities.dto";

export const TellsyActivitiesActions = createActionGroup({
  source: "Projects/Tellsy Activities",
  events: {
    "Load Tellsy Activities": props<{ dto: LoadTellsyActivitiesDto }>(),
    "Load Tellsy Activities Success": props<{
      dto: LoadTellsyActivitiesSuccessDto;
    }>(),
  },
});
