import { createActionGroup, props } from "@ngrx/store";
import { LoadParticipantsSuccessDto } from "@store/projects/model/dto/tellsy-participant.dto";
import { ExcludedParticipantsDto } from "@store/projects/model/dto/line.dto";

export const TellsyParticipantsActions = createActionGroup({
  source: "Projects/Participants",
  events: {
    "Load Tellsy Participants": props<{ eventId: string }>(),
    "Load Tellsy Participants Success": props<{
      dto: LoadParticipantsSuccessDto;
    }>(),
    "Update Excluded Tellsy Participants": props<ExcludedParticipantsDto>(),
  },
});
