import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { DayDto } from "@store/projects/model/dto/day.dto";
import {
  CreateGradeDto,
  CreateGradeForMultipleParticipantDto,
  CreateGradeForMultipleParticipantSuccessDto,
  CreateGradeSuccessDto,
  CreateGradeTypeDto,
  CreateGradeTypeSuccessDto,
  CreateGradeTypesDto,
  CreateGradeTypesSuccessDto,
  DeleteGradeDto,
  DeleteGradeSuccessDto,
  DeleteGradeTypeDto,
  DeleteGradeTypeSuccessDto,
  EditAutoGradeTypesDto,
  EditAutoGradeTypesSuccessDto,
  EditGradeDto,
  EditGradeSuccessDto,
  EditGradeTypesDto,
  EditGradeTypesSuccessDto,
  EditMultipleGradesDto,
  EditMultipleGradesSuccessDto,
  GetAllGradesByActivityAndUserNameDto,
  GetAllGradesByActivityAndUserNameSuccessDto,
  GetAllowedAutoGradeTypesSuccessDto,
  GetAutoGradesDto,
  GetAutoGradesSuccessDto,
  GetGradeTypesByLineIdDto,
  GetGradeTypesByLineIdSuccessDto,
  GetGradesByActivityAndParticipantIdDto,
  GetGradesByActivityAndParticipantIdSuccessDto,
  GetGradesSummaryPerParticipantDto,
  GetGradesSummaryPerParticipantSuccessDto,
} from "@store/projects/model/dto/grade.dto";
import { LineDto } from "@store/projects/model/dto/line.dto";
import { ProjectDto } from "@store/projects/model/dto/projects.dto";
import { TellsyTeamDto } from "@store/projects/model/dto/team.dto";
import { TellsyActivityDto } from "@store/projects/model/dto/tellsy-activities.dto";

export const GradesActions = createActionGroup({
  source: "Projects/Grades",
  events: {
    "Load Grade Types By Line Id": props<{ dto: GetGradeTypesByLineIdDto }>(),
    "Load Grade Types By Line Id Success": props<{
      dto: GetGradeTypesByLineIdSuccessDto;
    }>(),

    "Create Grade Type": props<{ dto: CreateGradeTypeDto }>(),
    "Create Grade Type Success": props<{
      dto: CreateGradeTypeSuccessDto;
    }>(),

    "Create Grade Types": props<{ dto: CreateGradeTypesDto }>(),
    "Create Grade Types Success": props<{
      dto: CreateGradeTypesSuccessDto;
    }>(),

    "Edit Grade Types": props<{ dto: EditGradeTypesDto }>(),
    "Edit Grade Types Success": props<{
      dto: EditGradeTypesSuccessDto;
    }>(),

    "Delete Grade Type": props<{ dto: DeleteGradeTypeDto }>(),
    "Delete Grade Type Success": props<{
      dto: DeleteGradeTypeSuccessDto;
    }>(),

    "Load Grades Summary For Day": props<{
      dto: GetGradesSummaryPerParticipantDto;
    }>(),
    "Load Grades Summary For Day Success": props<{
      dto: GetGradesSummaryPerParticipantSuccessDto;
    }>(),

    "Create Grade": props<{ dto: CreateGradeDto }>(),
    "Create Grade Success": props<{
      dto: CreateGradeSuccessDto;
    }>(),

    "Edit Grade": props<{ dto: EditGradeDto }>(),
    "Edit Grade Success": props<{
      dto: EditGradeSuccessDto;
    }>(),

    "Edit Multiple Grades": props<{ dto: EditMultipleGradesDto }>(),
    "Edit Multiple Grades Success": props<{
      dto: EditMultipleGradesSuccessDto;
    }>(),

    "Create Grade For Multiple Participant": props<{
      dto: CreateGradeForMultipleParticipantDto;
    }>(),
    "Create Grade For Multiple Participant Success": props<{
      dto: CreateGradeForMultipleParticipantSuccessDto;
    }>(),

    "Delete Grade": props<{ dto: DeleteGradeDto }>(),
    "Delete Grade Success": props<{
      dto: DeleteGradeSuccessDto;
    }>(),

    "Load All Grades By Activity And User Name": props<{
      dto: GetAllGradesByActivityAndUserNameDto;
    }>(),
    "Load All Grades By Activity And User Name Success": props<{
      dto: GetAllGradesByActivityAndUserNameSuccessDto;
    }>(),

    "Load Grades By Activity And ParticipantId": props<{
      dto: GetGradesByActivityAndParticipantIdDto;
    }>(),
    "Load Grades By Activity And ParticipantId Success": props<{
      dto: GetGradesByActivityAndParticipantIdSuccessDto;
    }>(),

    "Load Auto Grades": props<{
      dto: GetAutoGradesDto;
    }>(),
    "Load Auto Grades Success": props<{
      dto: GetAutoGradesSuccessDto;
    }>(),

    "Edit Auto Grade Types": props<{
      dto: EditAutoGradeTypesDto;
    }>(),
    "Edit Auto Grade Types Success": props<{
      lineId: LineDto["id"];
      dayId: DayDto["id"];
      dto: EditAutoGradeTypesSuccessDto;
    }>(),

    "Load Allowed Auto Grade Types": emptyProps(),
    "Load Allowed Auto Grade Types Success": props<{
      dto: GetAllowedAutoGradeTypesSuccessDto;
    }>(),

    "Load Project Excel Report": props<{
      lineId: LineDto["id"];
      activities: TellsyActivityDto[];
      projectTitle: ProjectDto["title"];
      teams: TellsyTeamDto[];
    }>(),
    "Load Project Excel Report Success": emptyProps(),
    "Load Project Excel Report Fail": props<{
      error: string;
    }>(),
  },
});
