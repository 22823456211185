import { createSelector } from "@ngrx/store";
import { selectProjectsState } from "@store/projects/projects.module";
import { tellsyActivitiesSelectors } from "@store/projects/reducers/tellsy-activities.reducer";

const selectTellsyActivitiesState = createSelector(
  selectProjectsState,
  (state) => state.tellsyActivities
);

const selectAllTellsyActivities = createSelector(
  selectTellsyActivitiesState,
  tellsyActivitiesSelectors.selectAll
);

const selectAllTellsyActivitiesEntities = createSelector(
  selectTellsyActivitiesState,
  tellsyActivitiesSelectors.selectEntities
);

const selectIsActivitiesLoading = createSelector(
  selectTellsyActivitiesState,
  (state) => state.isLoading
);

export const TellsyActivitiesSelectors = {
  selectAllTellsyActivities,
  selectAllTellsyActivitiesEntities,
  selectIsActivitiesLoading,
};
