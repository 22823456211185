import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "@shared/api.config";
import {
  LoadTellsyEventsPageableDto,
  LoadTellsyEventsSuccessDto,
} from "@store/projects/model/dto/tellsy-event.dto";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TellsyEventsService {
  constructor(private http: HttpClient) {}

  loadTellsyEvents(
    pageable: LoadTellsyEventsPageableDto
  ): Observable<LoadTellsyEventsSuccessDto> {
    const url = API.tellsyEvents.loadEvents();

    const params: { search: string; page: number; ids?: string } = {
      search: pageable.search ?? "",
      page: pageable.page,
      ids: pageable?.ids?.map((id) => id.trim()).join(";") ?? "",
    };

    return this.http.get<LoadTellsyEventsSuccessDto>(url, {
      params,
    });
  }
}
