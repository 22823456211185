import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TellsyActivitiesActions } from "@store/projects/actions";
import { TellsyActivitiesService } from "@store/projects/services";
import { map, switchMap } from "rxjs";

@Injectable()
export class TellsyActivitiesEffects {
  loadTellsyActivities = createEffect(() => {
    return this.actions.pipe(
      ofType(TellsyActivitiesActions.loadTellsyActivities),
      switchMap(({ dto }) => this.service.loadTellsyActivities(dto)),
      map((dto) => TellsyActivitiesActions.loadTellsyActivitiesSuccess({ dto }))
    );
  });

  constructor(
    private actions: Actions,
    private service: TellsyActivitiesService
  ) {}
}
