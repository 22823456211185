import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "@shared/api.config";
import {
  AddProjectDto,
  AddProjectSuccessDto,
  DeleteProjectDto,
  DeleteProjectSuccessDto,
  LoadProjectSuccessDto,
  LoadProjectsSuccessDto,
  ProjectDto,
  UpdateProjectDto,
  UpdateProjectSuccessDto,
} from "@store/projects/model/dto/projects.dto";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProjectsService {
  constructor(private http: HttpClient) {}

  loadProjects(): Observable<LoadProjectsSuccessDto> {
    const url = API.projects.loadProjects();
    return this.http.get<LoadProjectsSuccessDto>(url);
  }

  loadProject(id: ProjectDto["id"]): Observable<LoadProjectSuccessDto> {
    const url = API.projects.loadProject(id);
    return this.http.get<LoadProjectSuccessDto>(url);
  }

  addProject(dto: AddProjectDto): Observable<AddProjectSuccessDto> {
    const url = API.projects.loadProjects();
    return this.http.post<AddProjectSuccessDto>(url, dto);
  }

  updateProject(dto: UpdateProjectDto): Observable<UpdateProjectSuccessDto> {
    const url = API.projects.updateProject(dto.id);
    return this.http.put<UpdateProjectSuccessDto>(url, dto);
  }

  deleteProject(dto: DeleteProjectDto): Observable<DeleteProjectSuccessDto> {
    const url = API.projects.deleteProject(dto.id);
    return this.http.delete<DeleteProjectSuccessDto>(url);
  }
}
